import React, { useEffect, useState, useContext } from 'react';
import '../assets/CSS/Profile.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Button,
  TextField
} from '@mui/material';
import { deleteUserPreferences, getUserPreferences } from '../services/PreferenceService';
import { updateNickName } from '../services/AccountService';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import { Spinner } from '../components/Spinner';
import profilePhoto from '../assets/img/profilePhoto.png';
import backgroundCover from '../assets/img/backgroundCover.png';
import { showAlert, confirmAlert, StackableAlert } from '../utils/AlertUtills';
const Profile = () => {
  const { userID, user, setUser } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [userPreference, setUserPreference] = useState({});
  const [editedNickName, setEditedNickName] = useState(user.Nickname || '');

  const handleOpen = (modal) => {
    setOpenModal(modal);
  };

  const handleClose = () => {
    setOpenModal(null);
  };

  const handleDelete = async () => {
    try {
      const result = await confirmAlert('info', '', 'Are you sure you want to delete this preference?', 'Yes', 'No');
      if (result.isConfirmed) {
        await deleteUserPreferences(userID, token);
        console.log('User preference deleted successfully');
        showAlert('success', '', 'User preference deleted successfully');
        setUserPreference({});
      }
    } catch (error) {
      showAlert('error', '', 'Error deleting user preference');
      console.error('errorDelete', error);
    }
  };

  var nickNameReg = /^[a-zA-Z]{0,10}$/;
  const handleProfile = async () => {
    try {
      if (!editedNickName || !nickNameReg.test(editedNickName)) {
        await StackableAlert('error', '', 'Nickname should only contain up to 10 alphabetical characters.', '', '1310');
        return;
      }

      const respNickName = await updateNickName(userID, token, editedNickName);
      setUser(respNickName.data);
      setOpenModal(false);
      showAlert('success', '', 'Nickname updated successfully');
    } catch (error) {
      showAlert('error', '', 'Failed to update the nickname', '', '1310');
      console.error('errorEdit', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(true);
      try {
        const res = await getUserPreferences(userID, token);
        setUserPreference(res.data || {});
      } catch (error) {
        if (error.response && error.response.status === 400) {
          showAlert('error', '', 'Failed to retrieve user preferences');
        } else {
          console.log(error);
        }
      } finally {
        setLoaded(false);
      }
    };
    fetchData();
  }, [userID, token]);

  return (
    <>
      {loaded ? (
        <div className="middle">
          <Spinner></Spinner>
        </div>
      ) : (
        <>
          <div className="container fullcont" style={{ overflowY: 'scroll' }}>
            {/* Cover Image */}
            <div className="coverimg"
              style={{
                backgroundImage: `url(${backgroundCover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: -1,
                height: '40vh'
              }}
            />

            {/* Card */}
            <Card className="cardz" style={{ maxWidth: 500, margin: 'auto', position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '10px' }}>
              <EditIcon style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={() => handleOpen('editProfile')} />
              {/* Modal */}
              <div >
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openModal === 'editProfile'}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <div>
                    <Fade in={openModal}>
                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '10px', border: 'none' }}>
                        <h4>Edit Profile</h4>
                        {/* Add your form components here */}
                        <TextField label="NickName" value={editedNickName} onChange={(e) => setEditedNickName(e.target.value)} /><br />
                        <p style={{ color: 'red' }}>{!nickNameReg.test(editedNickName) ? 'Nickname should only contain up to 10 alphabetical characters.' : ''}</p>
                        <Button className="butpro" onClick={handleProfile}>Save Changes</Button>
                        <Button className="butpro" onClick={handleClose}>Close</Button>
                      </div>
                    </Fade>
                  </div>
                </Modal>
              </div>
              <CardMedia
                component="img"
                alt="Circle Image"
                height="20%"
                image={profilePhoto}
                style={{ borderRadius: '50%', width: '20%', marginTop: '10px' }}
              />
              <CardContent style={{ width: '100%', margin: 'auto', position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography className="profont" variant="h5" component="div">
                  {user.Nickname || ''}
                </Typography>
                {/* <Typography className="profont" variant="body2" color="textSecondary" style={{ textAlign: 'center', margin: '5px' }}>
                  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate
                </Typography> */}
              </CardContent>

            </Card>
            <Card style={{ marginTop: '10px' }}>
              {userPreference.Make && userPreference.Model && (
                <>
                  <Typography className="profont" variant="h5" component="div" style={{ margin: '10px 20px 5px 20px' }}>
                    Favourites
                  </Typography>
                  <hr style={{ margin: '5px 20px' }} />
                  <div className="col-12 col-md-4 lg-4 sm-4 xs-4">
                    <CardContent>
                      <div className="container prodetails">
                        <div className="row" style={{ textAlign: 'left', margin: '5px' }}>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Make</Typography>
                          </div>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                              <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.Make}</Typography>
                            </Card>
                          </div>
                        </div>
                        <div className="row" style={{ margin: '5px' }}>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Model</Typography>
                          </div>
                          <div className="col-12 md-6 sm-6 xs-6">
                            <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                              <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.Model}</Typography>
                            </Card>
                          </div>
                        </div>
                        {userPreference.FuelType && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Fuel Type</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.FuelType}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        {userPreference.StartYear && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• Start Year</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.StartYear}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        {userPreference.EndYear && (
                          <div className="row" style={{ margin: '5px' }}>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Typography className="profont" variant="body1" color="textPrimary" component="p" style={{ margin: '0' }}>• End Year</Typography>
                            </div>
                            <div className="col-12 md-6 sm-6 xs-6">
                              <Card style={{ marginLeft: '10px', padding: '5px', marginRight: '10px' }}>
                                <Typography className="profont" color="textPrimary" style={{ textAlign: 'left', margin: '0' }}>{userPreference.EndYear}</Typography>
                              </Card>
                            </div>
                          </div>
                        )}
                        <br /><br />
                        <DeleteIcon style={{ position: 'absolute', bottom: '10px', right: '50px', cursor: 'pointer' }} onClick={handleDelete} />
                      </div>
                    </CardContent>
                  </div>
                </>
              )}
            </Card>
          </div >
        </>
      )}
    </>
  );
};

export default Profile;