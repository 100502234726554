import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Stepper, Step, StepLabel } from '@mui/material';
import StepOne from './Stepone';
import StepTwo from './Steptwo';
import StepThree from './Stepthree';
import StepFour from './Stepfour';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  max-width: 700px;
  margin: 40px auto;
  padding-left:20px;
  padding-right:20px;
  @media (max-width: 590px) {
    /* width: 300px; */
    overflowX:"hidden !important";
    width:"100% !important";
  }
`;

const StepLabelContainer = styled.div`
  max-width: 850px;
  margin: 40px auto;
  padding-left:20px;
  padding-right:20px;
  @media (max-width: 590px) {
    /* width: 300px; */
    overflowX:"hidden !important";
    width:"100% !important";
  }
`;

const Claim = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeStepFromUrl = searchParams.get('activeStep');
  const [active, setActive] = useState(activeStepFromUrl ? parseInt(activeStepFromUrl) : 1);

  useEffect(() => {
    setActive(activeStepFromUrl ? parseInt(activeStepFromUrl) : 1);
  }, [activeStepFromUrl]);

  return (
    <div className="p-spacing">
      <div className="wrapper my-5" style={{ minHeight: "100%", position: "relative" }}>
        <StepLabelContainer>
          <Stepper activeStep={active - 1} alternativeLabel>
            <Step>
              <StepLabel>Claim</StepLabel>
            </Step>
            <Step>
              <StepLabel>Photo Upload</StepLabel>
            </Step>
            <Step>
              <StepLabel>Listing Pass</StepLabel>
            </Step>
            <Step>
              <StepLabel>List</StepLabel>
            </Step>
          </Stepper>
        </StepLabelContainer>
        <Container>
          {active === 1 && <StepOne />}
          {active === 2 && <StepTwo />}
          {active === 3 && <StepThree isActive={active === 3} />}
          {active === 4 && <StepFour />}
        </Container>
      </div>
    </div>
  );
};

export default Claim;
