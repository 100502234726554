// FeaturedVehicles.js

import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AOT_LOGO from '../assets/waka-logo/AOT_LOGO_GrayScale.png';
import { FaCar, FaCarAlt, FaCalendarAlt, FaTachometerAlt } from 'react-icons/fa';
import '../assets/CSS/FeaturedVehicles.css';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import { showAlert, confirmAlert } from '../utils/AlertUtills';
import { ButtonSpinner } from '../components/Spinner';

export default function RecipeReviewCard() {

  const navigate = useNavigate();
  const [featuredVehicles, setFeaturedVehicles] = useState(null);
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const [watchListLoadingStates, setWatchListLoadingStates] = useState([]);

  const handleVehicleCard = (VIN) => {
    navigate('/details', { plateNumberOrVIN: VIN });
  };

  const fetchActiveFeaturedVehicles = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_COMMON}getAllActiveFeaturedVehicles`, { userID });
      setFeaturedVehicles(response.data);
      // Initialize loading states for each card
      const initialLoadingStates = new Array(response.data.length).fill(false);
      setWatchListLoadingStates(initialLoadingStates);
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchActiveFeaturedVehicles();
  }, []);

  //function for add to watch listings
  async function addToWatchListings(claimID, index) {
    const result = await confirmAlert('info', 'Add to Watch Listings!', 'Confirm adding to watch listings?');
    if (result.isConfirmed) {
      const updatedLoadingStates = [...watchListLoadingStates];
      updatedLoadingStates[index] = true;
      setWatchListLoadingStates(updatedLoadingStates);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}addWatchListings`,
          { buyerID: userID, claimID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          await showAlert('success', '', 'Added successfully!');
        }
      } catch (error) {
        await showAlert('error', '', 'Adding to watched listing unsuccessful. Please try again!');
      } finally {
        fetchActiveFeaturedVehicles();
        updatedLoadingStates[index] = false;
        setWatchListLoadingStates(updatedLoadingStates);
      }
    }
  }

  //function for remove watched listings
  async function removeWatchedListings(claimID, index) {
    const result = await confirmAlert('info', 'Remove from Watched Listings!', 'Confirm removal from watched listings?');
    if (result.isConfirmed) {
      const updatedLoadingStates = [...watchListLoadingStates];
      updatedLoadingStates[index] = true;
      setWatchListLoadingStates(updatedLoadingStates);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}removeFromWatchedListings`,
          { buyerID: userID, claimID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          await showAlert('success', '', 'Removed successfully!');
        }
      }
      catch (error) {
        await showAlert('error', '', 'Removing unsuccessful. Please try again!');
      }
      finally {
        fetchActiveFeaturedVehicles();
        updatedLoadingStates[index] = false;
        setWatchListLoadingStates(updatedLoadingStates);
      }
    }
  }

  return (
    <>
      <div style={{ margin: '20px 0px 10px 0px' }}>
        {featuredVehicles && featuredVehicles.length > 0 && (
          <Button className="buttonOncard" variant="contained"
            style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3)' }}>
            Featured Vehicles
          </Button>
        )}
      </div>
      <div className="vehiclecards" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {featuredVehicles && featuredVehicles.map((vehicle, index) => (
          <div key={index} className="col-md-6 col-12 col-lg-4"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginLeft: '0px' }}>
            <Card sx={{ maxWidth: 300 }} className="SS  d-felx justify-content-center" key={index} >
              {vehicle.VehicleDetails.CompanyName ? (
                <>
                  <CardHeader
                    avatar={vehicle.VehicleDetails.CompanyLogoURL ?
                      <img
                        src={vehicle.VehicleDetails.CompanyLogoURL}
                        alt="Company Logo"
                        style={{ width: '40px', height: '40px' }}
                      />
                      : null}
                    title={vehicle.VehicleDetails.CompanyName}
                  />
                </>
              ) : <CardHeader
                avatar={
                  <div style={{ height: '40px' }}></div>
                }
              />}
              <div className='vehicleImage'>
                {userID && (vehicle.VehicleDetails.AddWatchListingBtnStatus || vehicle.VehicleDetails.RemoveWatchListingBtnStatus) && (
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      zIndex: 1,
                      background: 'white',
                      borderRadius: '50%',
                    }}
                  >
                    {watchListLoadingStates[index] ? (
                      <ButtonSpinner />
                    ) : (
                      <>
                        {vehicle.VehicleDetails.AddWatchListingBtnStatus && (
                          <VisibilityIcon onClick={() => addToWatchListings(vehicle.VehicleDetails.ClaimID, index)} />
                        )}
                        {vehicle.VehicleDetails.RemoveWatchListingBtnStatus && (
                          <VisibilityOffIcon onClick={() => removeWatchedListings(vehicle.VehicleDetails.ClaimID, index)} />
                        )}
                      </>
                    )}
                  </IconButton>
                )}
                <CardMedia
                  component="img"
                  image={vehicle.VehicleDetails.signedUrl || AOT_LOGO}
                  alt="Paella dish"
                  style={{ borderRadius: '8px' }}
                />
              </div>
              <CardContent style={{ padding: '0px 8px 30px 8px' }} onClick={() => handleVehicleCard(vehicle.FeaturedDetails.VIN)}>
                <Button className="buttonOncard" variant="contained" style={{}}>{vehicle.VehicleDetails.Make}</Button>
                <Typography variant="body2" color="text.secondary" >
                  <Grid container spacing={2} >
                    <Grid item xs={6} >
                      $  {vehicle.VehicleDetails.Price}
                    </Grid>
                    <Grid item xs={6} container alignItems="center">
                      <LocationOnIcon /> {vehicle.VehicleDetails.Location}
                    </Grid>
                    <hr />
                    <Grid item xs={6} style={{ borderTop: '1px solid grey' }}>
                      <FaCarAlt /> {vehicle.VehicleDetails.Model} <br />
                      <FaCalendarAlt /> {vehicle.VehicleDetails.YearOfManufacture}
                    </Grid>
                    <Grid item xs={6} style={{ borderTop: '1px solid grey' }}>
                      <FaCar /> {vehicle.VehicleDetails.BodyStyle} <br />
                      <FaTachometerAlt /> {vehicle.VehicleDetails.CurrentOdometerReading?.Reading} km
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
}

