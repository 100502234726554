import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { Button } from 'react-bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { ButtonSpinner } from './Spinner';
import '../assets/CSS/BuyerAmountOffer.css';
import { UserContext } from '../Context/UserContext';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import { showAlert } from '../utils/AlertUtills';

export default function UpdatePriceModal({ show, setShowModalPrice, prevPrice }) {
  const { claimObj, plateNumber, VIN, setVehiData } = useContext(VehicleDataContext);
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const [price, setPrice] = useState(prevPrice);
  const [errorMessage, setErrorMessage] = useState();
  const [priceLoading, setPriceLoading] = useState(false);
  const claimID = claimObj.ClaimID;
  var priceReg = /^(?=.*[1-9])\d*(\.\d{1,2})?$/;
  const updatePrice = async (e) => {
    //validation for offer amount
    if (!price || !priceReg.test(price)) {
      setErrorMessage('Price should be greater than 0 and can have up to two decimal places.');
    } else {
      setErrorMessage('');
      setPriceLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}/update-list-record`,
          { claimID, userID, price }, { headers: { Authorization: `Bearer ${token}` } }
        );
        const updatedListRecord = response.data;
        if (response.status === 200) {
          setVehiData(prevData => ({
            ...prevData,
            statusDetails: {
              ...prevData.statusDetails,
              ListRecord: {
                ...prevData.statusDetails.ListRecord,
                ...updatedListRecord
              }
            }
          }));
          const result = await showAlert('success', '', 'Price updated successfully!');
          if (result.isConfirmed) {
            setPrice(prevPrice);
            setErrorMessage('');
            setShowModalPrice(false);
          }
        }
      }
      catch (error) {
        showAlert('error', '', 'Failed to update the price');
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setPrice(prevPrice);
          setErrorMessage('');
          setShowModalPrice(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-2">
            <dl className="row">
              <dt className="col-sm-3">Plate Number/VIN:</dt>
              <dd className="col-sm-3">{plateNumber || VIN}</dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-3">Vehicle price (NZ$) </dt>
              <dd className="col-sm-4">
                <input type="text" min="0" className="form-control" placeholder="Enter price" onChange={(e) => {
                  if (priceReg.test(e.target.value.replace(/[^\d.]/g, ''))) {
                    setErrorMessage('');
                  }
                  setPrice(e.target.value.replace(/[^\d.]/g, ''));
                }} value={price} />{' '}
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </dd>
            </dl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={updatePrice}
            variant="danger"
            size="lg"
            disabled={priceLoading}
          >
            {priceLoading ? <ButtonSpinner /> : 'Update price'}
          </Button>
          <Button
            onClick={() => {
              setPrice(prevPrice);
              setErrorMessage('');
              setShowModalPrice(false);
            }}
            variant="danger"
            size="lg"
            disabled={priceLoading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
