import React from 'react';
import infoLogo from '../../assets/img/about.svg';
import star_empty from '../../assets/img/star-regular.svg';
import star_full from '../../assets/img/star-solid.svg';
import star_half from '../../assets/img/star-half-stroke-solid.svg';
import { Popover } from '@mui/material';
import '../CSS/Details.css';
import SafetyAndEnvironmentalRatingsIcon from '@mui/icons-material/EmojiTransportation'; // Safety and Environmental Ratings 
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default function Ratings({ ratingsData }) {

  // --------popover start---------
  const [fuelEconomyPop, setfuelEconomyPop] = React.useState(null);
  const [cleanCarPop, setcleanCarPop] = React.useState(null);
  const [safetyRatingPop, setsafetyRatingPop] = React.useState(null);


  const fuelEconomyInfoClick = (event) => {
    setfuelEconomyPop(event.currentTarget);
  };
  const cleanCarInfoClick = (event) => {
    setcleanCarPop(event.currentTarget);
  };
  const safetyRatingInfoClick = (event) => {
    setsafetyRatingPop(event.currentTarget);
  };

  const fuelEconomyInfoClose = () => {
    setfuelEconomyPop(null);
  };
  const cleanCarInfoClose = () => {
    setcleanCarPop(null);
  };
  const safetyRatingClose = () => {
    setsafetyRatingPop(null);
  };

  const openFuelEconomyPop = Boolean(fuelEconomyPop);
  const openCleanCarPop = Boolean(cleanCarPop);
  const openSafetyRatingPop = Boolean(safetyRatingPop);

  const idFuelEconomyPop = openFuelEconomyPop ? 'popover-4' : undefined;
  const idCleanCarPop = openCleanCarPop ? 'popover-5' : undefined;
  const idSafetyRatingPop = openSafetyRatingPop ? 'popover-6' : undefined;

  // function for display rating stars
  const renderRatingStars = (finalStarRating, totalStarCount) => {
    const stars = [];
    for (let i = 0; i < totalStarCount; i++) {
      if (i < Math.floor(finalStarRating)) {
        stars.push(<img key={i} src={star_full} alt="Full Star" className="Rating_Star" />);
      } else if (i === Math.floor(finalStarRating) && finalStarRating % 1 !== 0) {
        stars.push(<img key={i} src={star_half} alt="Half Star" className="Rating_Star" />);
      } else {
        stars.push(<img key={i} src={star_empty} alt="Empty Star" className="Rating_Star" />);
      }
    }
    return (
      <div className="Rating" aria-label={`Rating of this item is ${finalStarRating} out of ${totalStarCount}`}>
        {stars}
      </div>
    );
  };

  return (
    <>
      {ratingsData?.Ratings && (
        <>
          <div className="headin" style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title="Evaluation of a vehicle's safety features and its impact on the environment. This includes safety ratings based on crash tests and assessments of emissions and fuel efficiency."
              arrow
              placement="top"
              enterTouchDelay={0}
            >
              <IconButton><SafetyAndEnvironmentalRatingsIcon /></IconButton>
            </Tooltip>
            <h4 className='details_fc'>Safety and Environmental Ratings
            </h4>
          </div>
          <hr />
          <>
            {ratingsData.Ratings.ErrorCode < 30 ? ( //check ratings error code
              <div className='row'>
                {ratingsData.SafetyLabel && ratingsData.VFELLabel && (
                  <div className='col-md-6 col-sm-12'>
                    <>
                      <img className='mb-3' src={ratingsData.signedURLSafetyLabel} alt="S3 vfel" style={{ width: '300px', height: '200px' }} />
                      <div className='exclude-from-print'>
                        <br />
                      </div>
                      <img className='mb-3' src={ratingsData.signedURLVFELLabel} alt="S3 safety" style={{ width: '300px', height: '200px' }} />
                    </>
                  </div>
                )}
                <div className='col-md-6 col-sm-12'>
                  <div className='row'>
                    {(ratingsData.Ratings.SafetyStarsText || ratingsData.Ratings.DriverSafetyStars || ratingsData.Ratings.DriverSafetyTest) && (
                      <div className='col-md-6 col-sm-12'>
                        <div className='row-detail'>
                          <h6 className='details_fc'>
                            Safety Ratings
                          </h6>
                          <div className='exclude-from-print'>
                            <hr />
                          </div>
                          {ratingsData.Ratings.SafetyStarsText && (
                            <>
                              <span className='rating_det'> Safety Rating </span>
                              <img src={infoLogo} alt="info logo" className="info_logo" onClick={safetyRatingInfoClick} />
                              <Popover
                                id={idSafetyRatingPop}
                                open={openSafetyRatingPop}
                                style={{
                                  width: '90%',
                                }}
                                anchorEl={safetyRatingPop}
                                onClose={safetyRatingClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <p className='popover-text'>The more stars a vehicle has, the safer it is for those in the vehicle, and those that may be struck by the vehicle in a crash.</p>
                              </Popover>
                              {(() => {
                                var ratingString = ratingsData.Ratings.SafetyStarsText;
                                var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                return renderRatingStars(finalStarRating, totalStarCount); // Return the output from the renderRatingStars function
                              })()}
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}

                          {ratingsData.Ratings.DriverSafetyStars && (
                            <>
                              <span className='rating_det'> Driver safety
                              </span>
                              {(() => {
                                const finalStarRating = ratingsData.Ratings.DriverSafetyStars / 2;
                                const totalStarCount = 5;  //out of 5
                                return renderRatingStars(finalStarRating, totalStarCount);
                              })()}
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}
                          {ratingsData.Ratings.DriverSafetyTest && (
                            <>
                              <span className='rating_det'> Safety rating system
                              </span>
                              <div className="">
                                <span className='rating_h'><b>{ratingsData.Ratings.DriverSafetyTest}</b></span>
                              </div>
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {(ratingsData.Ratings.FuelStarsText || ratingsData.Ratings.CO2StarsText || ratingsData.Ratings.PollutantsStars) && (
                      <div className='col-md-6 col-sm-12'>
                        <>
                          <h6 className='details_fc'>
                            Environmental ratings
                            <div className='exclude-from-print'>
                              <hr />
                            </div>
                          </h6>
                          {ratingsData.Ratings.FuelStarsText && (
                            <>
                              <span className='rating_det me-1'> Fuel economy
                              </span>
                              {ratingsData.Ratings.FuelEconomyDisclaimer && (
                                <>
                                  <img src={infoLogo} alt="info logo" className="info_logo" onClick={fuelEconomyInfoClick} />
                                  <Popover
                                    id={idFuelEconomyPop}
                                    open={openFuelEconomyPop}
                                    style={{
                                      width: '90%',
                                    }}
                                    anchorEl={fuelEconomyPop}
                                    onClose={fuelEconomyInfoClose}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                  >
                                    <p className='popover-text'>{ratingsData.Ratings.FuelEconomyDisclaimer}</p>
                                  </Popover>
                                </>
                              )}
                              {(() => {
                                var ratingString = ratingsData.Ratings.FuelStarsText;
                                var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                return renderRatingStars(finalStarRating, totalStarCount);
                              })()}
                              {ratingsData.Ratings.FuelEconomyText && (
                                <>
                                  <span className='rating_det'> {ratingsData.Ratings.FuelEconomyText}</span>
                                </>
                              )}
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}
                          {ratingsData.Ratings.CO2StarsText && (
                            <>
                              <span className='rating_det'> Carbon emissions
                              </span>
                              {(() => {
                                var ratingString = ratingsData.Ratings.CO2StarsText;
                                var ratingValues = ratingString.match(/\d+(\.\d+)?/g); // Extract numeric values from the string
                                var finalStarRating = ratingValues[0]; // Assign the first number to finalStarRating
                                var totalStarCount = ratingValues[ratingValues.length - 1]; // Assign the last number to totalStarCount
                                return renderRatingStars(finalStarRating, totalStarCount);
                              })()}
                              {ratingsData.Ratings.CO2Text && (
                                <>
                                  <span className='rating_det'> {ratingsData.Ratings.CO2Text}
                                  </span>
                                </>
                              )}
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}
                          {ratingsData.Ratings.PollutantsStars && (
                            <>
                              <span className='rating_det'> Air pollution
                              </span>
                              {(() => {
                                const finalStarRating = ratingsData.Ratings.PollutantsStars / 2;
                                const totalStarCount = 6;  //out of 12
                                return renderRatingStars(finalStarRating, totalStarCount);
                              })()}
                              <div className='exclude-from-print'>
                                <br />
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    {(ratingsData.Ratings.CleanVehicleText || ratingsData.Ratings.CO2Disclaimer) && (
                      <div className='col-lg-12 col-md-12 col-sm-12'>
                        <span className='rating_det me-1'> Clean Car rebate or fee
                        </span>
                        {ratingsData.Ratings.CleanVehicleDisclaimerLong && (
                          <>
                            <img src={infoLogo} alt="info logo" className="info_logo" onClick={cleanCarInfoClick} />
                            <Popover
                              id={idCleanCarPop}
                              open={openCleanCarPop}
                              style={{
                                width: '90%',
                              }}
                              anchorEl={cleanCarPop}
                              onClose={cleanCarInfoClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <p className='popover-text'>{ratingsData.Ratings.CleanVehicleDisclaimerLong}</p>
                            </Popover>
                          </>
                        )}
                        <div className='rating_clean_car'>
                          <div className='py-2 px-2'>
                            {ratingsData.Ratings.CleanVehicleText && (
                              <>
                                <span><b>{ratingsData.Ratings.CleanVehicleText}</b></span>
                                <div className='exclude-from-print'>
                                  <br />
                                </div>
                              </>
                            )}
                            {ratingsData.Ratings.CO2Disclaimer && (
                              <>
                                <span className='rating_det'> {ratingsData.Ratings.CO2Disclaimer}</span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-md-6 col-lg-6 col-sm-6 col-xs-6 row-detail'>
                  <h6 className='details_fc'>
                    <a href={'https://rightcar.govt.nz/'} target='_blank' rel='noopener noreferrer'>Please click and find Ratings</a>
                  </h6>
                </div>
              </div>
            )}
          </>
          <div className='exclude-from-print'>
            <hr />
          </div>
        </>
      )}
    </>
  );
}
