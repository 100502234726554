import React, { useEffect, useContext, useState } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';
import { getCurrentUser } from "aws-amplify/auth";

const PrivateRoute = ({ children }) => {
  const { userStatus } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };
    getAuthenticatedUser();
  }, []);

  if (isAuthenticated === null) {
    return null;
  }

  if (!isAuthenticated || userStatus !== 'Active') {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;