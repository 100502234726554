import React, { useState, useEffect, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import BuyerAmountOffer from './components/BuyerAmountOffer';
import SingleSignOn from './components/single-sign-on';
import Details from './pages/details/Details';
import Home from './pages/Home';
import ReceivedOffers from './components/ReceivedOffers';
import PlacedOffers from './components/PlacedOffers';
import PurchaseComplete from './components/PurchaseComplete';
import RequestedVehiList from './components/RequestedVehiList';
import SoldVehicles from './components/SoldVehicles';
import BoughtVehicles from './components/BoughtVehicles';
import { EmailContext, jwtToken } from './Context/DataContext';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Claim from './pages/claim/Claim';
import FAQs from './pages/FAQs';
import { fetchAuthSession } from 'aws-amplify/auth';
import Photoupload from './pages/claim/Steptwo';
import Chat from './pages/chat/chatScreen';
import AdvancedSearch from './pages/AdvancedSearch';
import { GlobalProvider } from './Context/GlobalContext';
import { ChatProvider } from './Context/ChatContext';
import { UserProvider } from './Context/UserContext';
import { VehicleDataProvider } from './Context/VehicleDataContext';
import Account from './pages/Account';
import Profile from './pages/Profile';
import { MetadataProvider } from './Context/MetadataContext';
import Footer from "./components/Footer";
import PrivateRoute from './components/PrivateRoute';
import { SearchProvider } from './Context/SearchContext';
// import './aws-config';
import { ChatBotProvider } from './Context/ChatBotContext';

function App() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  // compentDidMount() function
  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const { tokens } = await fetchAuthSession();

        if (tokens?.idToken) {
          const userEmail = tokens.idToken.payload.email;
          const idToken = tokens.idToken.toString();

          setEmail(userEmail);
          setToken(idToken);
        }
      } catch (error) {
        // If there's no session or token has expired, clear the states
        setEmail('');
        setToken('');
        console.error('Auth Session Error:', error.message);
      }
    };
    getUserAttributes();
  }, []);

  return (
    <>
      <Router>
        {/* Other Routes*/}
        <div className="app-wrapper">
          <div className="content">
            <MetadataProvider>
              <SearchProvider>
                <jwtToken.Provider value={{ token, setToken }}>
                  <EmailContext.Provider value={{ email, setEmail }}>
                    <VehicleDataProvider>
                      <UserProvider>
                        <GlobalProvider>
                          <ChatProvider>
                            <ChatBotProvider>
                              <Navbar />
                              <Routes>
                                <Route path="/signin" element={<SingleSignOn />} />
                                <Route path="/" exact element={<Home />} />
                                <Route path="/details" element={<Details />} />
                                <Route exact path="/purchase" element={<PrivateRoute><PurchaseComplete /></PrivateRoute>} />
                                <Route exact path="/claim/:listStatus" element={<PrivateRoute><Claim /></PrivateRoute>} />
                                <Route exact path="/photoupload" element={<PrivateRoute><Photoupload /></PrivateRoute>} />
                                <Route exact path="/BuyerAmountOffer" element={<PrivateRoute><BuyerAmountOffer /></PrivateRoute>} />
                                <Route exact path="/receivedOffers" element={<PrivateRoute><ReceivedOffers /></PrivateRoute>} />
                                <Route exact path="/requestedVehiList" element={<PrivateRoute><RequestedVehiList /></PrivateRoute>} />
                                <Route exact path="/placedOffers" element={<PrivateRoute><PlacedOffers /></PrivateRoute>} />
                                <Route exact path="/soldVehicles" element={<PrivateRoute><SoldVehicles /></PrivateRoute>} />
                                <Route exact path="/boughtVehicles" element={<PrivateRoute><BoughtVehicles /></PrivateRoute>} />
                                <Route exact path="/vehiclePasses" element={<PrivateRoute><Pricing /></PrivateRoute>} />
                                <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                <Route path="/termsConditions" element={<TermsConditions />} />
                                <Route path="/FAQs" element={<FAQs />} />
                                <Route path="/message" element={<PrivateRoute><Chat /></PrivateRoute>} />
                                <Route path="/advancedSearch" element={<AdvancedSearch />} />
                                <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
                                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                              </Routes>
                            </ChatBotProvider>
                          </ChatProvider>
                        </GlobalProvider>
                      </UserProvider>
                    </VehicleDataProvider>
                  </EmailContext.Provider>
                </jwtToken.Provider>
              </SearchProvider>
            </MetadataProvider>
          </div>
        </div>
        <Footer />
      </Router >
    </>
  );
}

export default App;
