import React, { useState } from 'react';
import { useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { jwtToken } from '../Context/DataContext';
import { UserContext } from '../Context/UserContext';
import { ButtonSpinner } from './Spinner';
import { vehicleReport } from '../services/PaymentService';
import { showAlert } from '../utils/AlertUtills';

function VehicleReportModal({ show, setShowVehicleDetails, vin, plate }) {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const [isValuationCheck, setIsValuationCheck] = useState(false);
  const [isMoneyOwingCheck, setIsMoneyOwingCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const generateVehicleReport = async (e) => {
    e.preventDefault();

    try {
      setCheckoutLoading(true);
      const response = await vehicleReport(userID, token, {
        VIN: vin,
        moneyOwingCheck: isMoneyOwingCheck,
        vehicleValueCheck: isValuationCheck
      });
      setCheckoutLoading(false);
      window.location.replace(response.data);
    } catch (error) {
      showAlert('error', '', 'Failed to generate vehicle report');
    }
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setErrorMessage('');
          setShowVehicleDetails(false);
          setIsValuationCheck(false);
          setIsMoneyOwingCheck(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate vehicle report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-2">
            <dl className="row">
              <dt className="col-sm-4">Plate Number/VIN</dt>
              <dd className="col-sm-6">
                <input type="text" min="0" disabled className="form-control" placeholder="Enter plate number/VIN" value={plate || vin} />{' '}
                <p style={{ color: 'red' }}>{errorMessage}</p>
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Money owing</dt>
              <dd className="col-sm-4">
                <Form.Check
                  type='checkbox'
                  id={`valuation-checkbox`}
                  checked={isMoneyOwingCheck}
                  onChange={(e) => setIsMoneyOwingCheck(e.target.checked)}
                />
              </dd>
            </dl>
            <dl className="row">
              <dt className="col-sm-4">Vehicle value</dt>
              <dd className="col-sm-4">
                <Form.Check
                  type='checkbox'
                  id={`valuation-checkbox`}
                  checked={isValuationCheck}
                  onChange={(e) => setIsValuationCheck(e.target.checked)}
                />
              </dd>
            </dl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={generateVehicleReport}
            variant="danger"
            size="lg"
            disabled={checkoutLoading}
          >
            {checkoutLoading ? <ButtonSpinner /> : 'Generate report'}
          </Button>
          <Button
            onClick={() => {
              setErrorMessage('');
              setShowVehicleDetails(false);
              setIsValuationCheck(false);
              setIsMoneyOwingCheck(false);
            }}
            variant="danger"
            size="lg"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default VehicleReportModal;