import React, { useEffect, useState, useContext } from 'react';
import '../assets/CSS/Regisration.css';
import google from '../assets/social-icons/google.png';
import facebook from '../assets/social-icons/facebook.png';
import amazon from '../assets/social-icons/amazon.png';
import apple from '../assets/social-icons/apple.png';
import { signOut, signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import { showAlert } from '../utils/AlertUtills';
import { clearDetailsLocalStorage } from './../utils/LocalStorageUtils';
import waka_logo from '../assets/img/waka-logo.png';
import { performRedirect } from '../utils/Redirect';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import { GlobalContext } from '../Context/GlobalContext';

export default function SingleSignOn() {
  const navigate = useNavigate();
  const { setUserID, setUserStatus, setUser } = useContext(UserContext);
  const [loaded, setLoaded] = useState(true);
  const { plateNumber, VIN } = useContext(VehicleDataContext);
  const { previousLocation, setPreviousLocation } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      setLoaded(true);
      try {
        const { tokens } = await fetchAuthSession();

        // Get the ID token for the API call
        const idToken = tokens.idToken.toString();
        const userEmail = tokens.idToken.payload.email;
        // Fetch user details
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_COMMON}user`,
            { email: userEmail },
            {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            }
          );

          setUserID(response.data.UserID);
          setUserStatus(response.data.Status);
          setUser(response.data);

          if (response.data.Status !== 'Active') {
            const statusMessage = {
              Blocked: 'Your account has been blocked!',
              Suspended: 'Your account has been suspended!',
            }[response.data.Status] || 'Your account status is invalid!';

            const result = await showAlert('error', '', statusMessage);
            if (result.isConfirmed) {
              clearDetailsLocalStorage();
              try {
                await signOut();
              } catch (error) {
                await showAlert('error', '', 'Failed to sign out. Please try again.');
              }
            }
          } else {
            performRedirect(plateNumber, VIN, previousLocation, setPreviousLocation, navigate);
          }

        } catch (error) {
          const result = await showAlert('error', '', 'Failed to retrieve the user details');
          if (result.isConfirmed) {
            clearDetailsLocalStorage();
            try {
              await signOut();
            } catch (error) {
              await showAlert('error', '', 'Failed to sign out. Please try again.');
            }
          }
        }
      } catch (error) {
        console.error('Auth Error:', error);
        await signOut();
      } finally {
        setLoaded(false);
      }
    })();
  }, []);

  const handleGoogleSignIn = async () => {
    signInWithRedirect({
      provider: 'Google'
    });
  };

  const handleFacebookSignIn = async () => {
    await signInWithRedirect({
      provider: 'Facebook'
    });
  };

  const handleAmazonSignIn = async () => {
    await signInWithRedirect({
      provider: 'Amazon'
    });
  };

  const handleAppleSignIn = async () => {
    await signInWithRedirect({
      provider: 'Apple'
    });
  };

  return (
    <>
      {loaded ? (<div className="middle"><Spinner></Spinner></div>) : (
        <div className="App" >
          <div className="outer chat_texts">
            <div className="inner">
              <div className="waka_logo">
                <img src={waka_logo} alt="Waka Logo" />
              </div>
              <div className="">
                <label className="custom-control-label">Sign in With Your Social Account</label>
              </div>
              <div className="social-buttons mt-2">
                {process.env.REACT_APP_ENABLE_GOOGLE_SSO === 'true' && <button
                  onClick={handleGoogleSignIn}
                  className="SSO-Google btn-lg btn-block mt-3"
                >
                  <img className="social" src={google} alt="google-sign" />
                  Continue with Google
                </button>}
                {process.env.REACT_APP_ENABLE_FACEBOOK_SSO === 'true' && <button
                  className="SSO-Facebook btn-lg btn-block mt-3"
                  onClick={handleFacebookSignIn}
                >
                  <img className="social" src={facebook} alt="facebook-sign" />
                  Continue with Facebook
                </button>}
                {process.env.REACT_APP_ENABLE_AMAZON_SSO === 'true' && <button
                  className="SSO-Amazon btn-lg btn-block mt-3"
                  onClick={handleAmazonSignIn}
                >
                  <img className="social" src={amazon} alt="Amazon-sign" />
                  Continue with Amazon
                </button>}
                {process.env.REACT_APP_ENABLE_APPLE_SSO === 'true' && <button
                  className="SSO-Apple btn-lg btn-block mt-3"
                  onClick={handleAppleSignIn}
                >
                  <img className="social" src={apple} alt="Apple-sign" />
                  Continue with Apple
                </button>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
