import Swal from 'sweetalert2';

export const showAlert = async (icon, title, message, confirmButtonColor) => {
  const result = await Swal.fire({
    icon: icon,
    title: title,
    text: message,
    confirmButtonText: 'OK',
    confirmButtonColor: confirmButtonColor || '#c9302c',
  });
  return result;
};

export const StackableAlert = async (icon, title, message, confirmButtonColor, zIndex) => {
  const result = await Swal.fire({
    icon: icon,
    title: title,
    text: message,
    confirmButtonText: 'OK',
    confirmButtonColor: confirmButtonColor || '#c9302c',
    didOpen: () => {
      const container = Swal.getContainer(); // Get the .swal2-container element
      if (container) {
        container.style.zIndex = zIndex || 1060; // Set the z-index dynamically
      }
    },
  });
  return result;
};

export const confirmAlert = async (icon, title, message, confirmButtonText, cancelButtonText, confirmButtonColor) => {
  const result = await Swal.fire({
    icon: icon,
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonText: confirmButtonText || 'Yes',
    cancelButtonText: cancelButtonText || 'No',
    confirmButtonColor: confirmButtonColor || '#c9302c',
  });
  return result;
};

export const confirmHTMLAlert = async (icon, title, message, confirmButtonText, cancelButtonText, confirmButtonColor) => {
  const result = await Swal.fire({
    icon: icon,
    title: title,
    html: message,
    showCancelButton: true,
    confirmButtonText: confirmButtonText || 'Yes',
    cancelButtonText: cancelButtonText || 'No',
    confirmButtonColor: confirmButtonColor || '#c9302c',
  });
  return result;
};