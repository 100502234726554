import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { showAlert } from '../utils/AlertUtills';
import { UserContext } from '../Context/UserContext';

export default function NicknameModal({ userID, nickname }) {
  const { token } = useContext(jwtToken);
  const { user, setUser, userStatus } = useContext(UserContext);
  const [newNickname, setNewNickname] = useState(user.Nickname || '');
  const { nicknameModalShown, setNicknameModalShown } = useContext(UserContext);
  const [showNickname, setShowNickname] = useState(!user.Nickname && userStatus === 'Active' && nicknameModalShown === 'false' ? true : false);
  const [errorMessage, setErrorMessage] = useState();

  var nickNameReg = /^[a-zA-Z]{0,10}$/;
  async function updateNickname() {
    if (!newNickname || !nickNameReg.test(newNickname)) {
      setErrorMessage('Nickname should only contain up to 10 alphabetical characters.');
    } else {
      try {
        setShowNickname(false);
        setNicknameModalShown('true');

        if (newNickname) {
          nickname = newNickname;
        }
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}updateaccount`, { userID, nickname }, { headers: { Authorization: `Bearer ${token}` } });
        setUser(response.data);
        await showAlert('success', '', 'Nickname updated successfully!');
      } catch (error) {
        await showAlert('error', '', 'Failed to update the nickname');
      }
    }
  }

  return (
    <>
      <Modal
        show={showNickname}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => { setShowNickname(false); setNicknameModalShown('true'); }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div >
            <div>Do you like to add a nickname?</div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter the nickname"
              value={newNickname}
              onChange={(e) => {
                if (nickNameReg.test(e.target.value.replace(/[^a-zA-Z]/g, ''))) {
                  setErrorMessage('');
                  setNewNickname(e.target.value.replace(/[^a-zA-Z]/g, ''));
                } else {
                  setErrorMessage('Nickname should only contain up to 10 alphabetical characters.');
                }
              }}
              id="exampleFormControlInput1"
            />
            <p style={{ color: 'red' }}>{errorMessage}</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary" onClick={updateNickname}>
            Submit
          </button>
          <button className="btn btn-secondary" onClick={() => { setShowNickname(false); setNicknameModalShown('true'); }}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
