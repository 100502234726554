import React, { useState, useContext } from 'react';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { showAlert } from '../utils/AlertUtills';
import '../assets/CSS/DescriptionModel.css';
import { UserContext } from '../Context/UserContext';
import { VehicleDataContext } from '../Context/VehicleDataContext';
// import heic2any from 'heic2any';

export default function MultipleImageUpload({ imgUploadModel, setImgUploadModel, claimID, fetchVehicleImages, images, setIsLoading }) {
  const imageLimit = parseInt(process.env.REACT_APP_MAX_IMG_LIMIT);
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const { setClaimObj, setVehiData } = useContext(VehicleDataContext);
  const [selectedImages, setSelectedImages] = useState([]); // Add state for selected images
  const isDefault = false;

  const onChange = (e) => {
    e.preventDefault();

    // ### Add logic to handle selected files
    const selectedFiles = e.target.files || e.dataTransfer.files; // Rename 'files' to 'selectedFiles'
    const selected = Array.from(selectedFiles);

    // Append newly selected images to the existing selectedImages array
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...selected.map((file) => {
        file.id = Date.now() + file.name; // Add the 'id' property to the 'file' object
        return file; // Return the modified 'file' object
      }),
    ]);

  };

  const removeSelectedFile = (fileToRemove) => {
    // Remove the selected image based on its unique identifier
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.filter((image) => image.id !== fileToRemove.id)
    );
  };

  //convert to heic
  // async function convertHeicToJpeg() {
  //   let convertedImages = [];
  //   for (const selectedFile of selectedImages) {
  //     console.log('selectedFile', selectedFile);

  //     if (selectedFile.type === "image/heic" || selectedFile.type === "image/heif") {
  //       console.log('selectedFile', selectedFile);

  //       try {
  //         const convertedImage = await heic2any({ blob: selectedFile, toType: "image/jpeg", quality: 0.5 });
  //         convertedImages.push(convertedImage);

  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       convertedImages.push(selectedFile);
  //     }
  //   }
  //   if (convertedImages.length > 0) {
  //     return convertedImages;
  //   }

  // }

  const uploadFiles = async () => {
    try {
      setImgUploadModel(false);
      setIsLoading(true);
      const errors = [];
      if (selectedImages && selectedImages.length > 0) {
        // Create an array to store the upload promises
        const uploadPromises = selectedImages.map(async (selectedFile) => {
          try {
            // Get the signed URL
            const response = await axios.post(`${process.env.REACT_APP_API_COMMON}image-signedurl`,
              { userID, claimID, fileType: selectedFile.type, action: 'upload' }, { headers: { Authorization: `Bearer ${token}` } });

            const { signedUrl, imageID } = response.data;
            // Upload the image to the signed URL
            const imageUploadResult = await fetch(signedUrl, {
              method: 'PUT',
              body: selectedFile,
            });

            if (imageUploadResult.ok) {
              const urlObject = new URL(imageUploadResult.url);
              const imageURL = urlObject.origin + urlObject.pathname;

              return { imageID, imageURL };
            } else {
              errors.push(`Failed to store an image`);
              return null;
            }
          } catch (error) {
            if (error.response?.data === 'You do not have sufficient permissions to upload or delete images for a vehicle claimed by the dealer company.') {
              errors.push(error.response.data);
            } else if (error.response?.data === 'Only authorized dealer personnel can upload or delete images for vehicles claimed by the dealer company.') {
              errors.push(error.response.data);
            } else {
              errors.push(`Failed to store an image`);
            }
            return null;
          }
        });

        // Wait for all image uploads to complete in parallel
        const uploadedImages = await Promise.all(uploadPromises);

        // Process the uploaded images sequentially
        for (const uploadedImage of uploadedImages) {
          if (uploadedImage) {
            const { imageID, imageURL } = uploadedImage;

            // Store the image one by one
            const storeImgResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}store-image`,
              { userID, claimID, imageID, imageURL, isDefault }, { headers: { Authorization: `Bearer ${token}` } });

            if (storeImgResponse.status === 200) {
              setVehiData(prevVehiData => ({
                ...prevVehiData,
                statusDetails: {
                  ...prevVehiData.statusDetails,
                  ClaimRecord: storeImgResponse.data,
                },
              }));
              setClaimObj(storeImgResponse.data);
            } else {
              errors.push(`Failed to store an image`);
            }
          }
        }

        if (fetchVehicleImages) {
          await fetchVehicleImages();
        }
        setSelectedImages([]);

        if (errors.length > 0) {
          if (errors.some(error => error === 'You do not have sufficient permissions to upload or delete images for a vehicle claimed by the dealer company.')) {
            await showAlert('error', '', 'You do not have sufficient permissions to upload images for a vehicle claimed by the dealer company.');
          } else if (errors.some(error => error === 'Only authorized dealer personnel can upload or delete images for vehicles claimed by the dealer company.')) {
            await showAlert('error', '', 'Only authorized dealer personnel can upload images for vehicles claimed by the dealer company.');
          } else {
            await showAlert('error', '', 'Failed to upload or store one or more photos. Please try again later.');
          }
        } else {
          await showAlert('success', '', 'Images uploaded successfully');
        }
      }
    } catch (error) {
      await showAlert('error', 'Error', 'Failed to upload or store one or more photos. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={imgUploadModel}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setImgUploadModel(false);
          setSelectedImages([]);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Vehicle Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {(images && images.length < imageLimit) ? ( // ### Check total images limit
              <div className="mb-5 d-flex justify-content-center flex-column align-items-left justify-content-sm-start justify-content-md-start
              justify-content-lg-start justify-content-xl-start" >
                <Container fluid>
                  <Row className="mb-5 justify-content-center">
                    <Row lg={10} md={3} sm={3} xs={3} >
                      <Col lg={4} md={3} sm={3} xs={3} className="p-2">
                        <input type="file" accept="image/*" id='file-input' onChange={onChange} multiple style={{ display: 'none' }} />
                        <Button variant="danger" className="red" size='sm' onClick={() => { document.getElementById('file-input').click(); }}>Browse</Button>
                      </Col>
                      <Col lg={4} md={3} sm={3} xs={3} className="p-2">
                        <span style={{ color: 'black' }}>{selectedImages.length > 0 ? selectedImages.length + " images selected" : " no files selected"}</span>
                      </Col>
                    </Row>
                  </Row>
                </Container>
                {selectedImages.length > 0 && (
                  <div className="mt-3">
                    <h5>Selected Files:</h5>
                    <ul>
                      {selectedImages.map((selectedImage) => (
                        <li key={selectedImage.id} className="mr-2 mb-2">
                          <img
                            src={URL.createObjectURL(selectedImage)} // Display thumbnail using a data URL
                            alt={selectedImage.name}
                            style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '4vw', minWidth: '100px', minHeight: '100px' }}
                          />
                          <Button variant="danger" className="red" size='sm' style={{ maxWidth: '100px' }} onClick={() => removeSelectedFile(selectedImage)}>Remove</Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <br />
                <br />
                <br /><br />
              </div>
            ) : (
              <div>
                Maximum image upload limit reached. You can upload a maximum of {imageLimit} images.
              </div>
            )}

            {(images && images.length + selectedImages.length > imageLimit &&
              <div>
                Maximum image upload limit reached. You can upload a maximum of {imageLimit} images.
              </div>
            )}

            <Container fluid>
              <Row className="mb-5 justify-content-center">
                <Row lg={10} md={3} sm={3} xs={3} >
                  <Col lg={4} md={3} sm={3} xs={3} className="p-2">
                    <Button variant="danger" id="upload-btn" className="red"
                      onClick={() => uploadFiles()}
                      disabled={(images && images.length + selectedImages.length > imageLimit) || (images && images.length >= imageLimit)}
                    >
                      Upload
                    </Button>
                  </Col>

                  <Col lg={4} md={3} sm={3} xs={3} className="p-2">
                    <Button variant="danger" id="cancel-btn" className="red"
                      onClick={() => {
                        setImgUploadModel(false);
                      }}  >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Container>
          </>
        </Modal.Body>
      </Modal >
    </>
  );
}
