import { useState, useEffect, useCallback, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { ButtonSpinner } from '../../components/Spinner';
import { AiFillDelete } from 'react-icons/ai';
import { FiUpload } from 'react-icons/fi';
import { jwtToken } from '../../Context/DataContext';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import MultipleImageUpload from '../../components/MultipleImageUpload';
import { showAlert, confirmAlert } from '../../utils/AlertUtills';
import axios from 'axios';
import '../CSS/ImageSlider.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

export default function ImageSlider({ claim }) {
  const { setClaimObj, setVehiData } = useContext(VehicleDataContext);
  const [images, setImages] = useState([]);
  const [validImages, setValidImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgUploadModel, setImgUploadModel] = useState(false);
  const [showUploadDeleteBtn, setShowUploadDeleteBtn] = useState(false);
  const { token } = useContext(jwtToken);
  const { userID, user } = useContext(UserContext);
  const claimID = claim?.ClaimID || null;

  const fetchVehicleImages = useCallback(async () => {
    if (claimID) {
      try {
        setIsLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}get-images`, { claimID });
        setImages(response.data);
      } catch (error) {
        await showAlert('error', '', 'Failed to get the images.');
      } finally {
        setIsLoading(false);
      }
    }
  }, [claimID]);

  useEffect(() => {
    setShowUploadDeleteBtn(false);
    if (claim.DealerID) {
      if (claim.DealerID === user.DealerID) {
        setShowUploadDeleteBtn(true);
      }
    } else {
      if (claim.OwnerID === user.UserID) {
        setShowUploadDeleteBtn(true);
      }
    }
  }, [claim, user]);

  useEffect(() => {
    fetchVehicleImages();
  }, [fetchVehicleImages]);

  const validateImages = useCallback(async () => {
    if (images) {
      setIsLoading(true);
      const validatedImages = await Promise.all(
        images.map((image) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(image);
            img.onerror = () => reject();
            img.src = image.signedURL;
          });
        })
      );

      const sortedImages = validatedImages
        .filter((image) => image)
        .sort((a, b) => {
          if (a.IsDefault && !b.IsDefault) return -1;
          if (!a.IsDefault && b.IsDefault) return 1;
          return 0;
        });

      setValidImages(sortedImages);
      setIsLoading(false);
    }
  }, [images]);

  useEffect(() => {
    validateImages();
  }, [validateImages]);

  const deleteImage = async (imageID) => {
    try {
      const result = await confirmAlert('info', '', 'Are you sure you want to delete this image?',);
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}image-signedurl`,
            { userID, claimID, imageID, action: 'delete' }, { headers: { Authorization: `Bearer ${token}` } });

          const { signedUrl } = response.data;

          const imageDeleteResult = await fetch(signedUrl, {
            method: 'DELETE',
          });

          if (imageDeleteResult.ok) {
            const deleteImgResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}remove-image`,
              { userID, claimID, imageID }, { headers: { Authorization: `Bearer ${token}` } });

            if (deleteImgResponse.status === 200) {
              await fetchVehicleImages();
              setVehiData((prevVehiData) => ({
                ...prevVehiData,
                statusDetails: {
                  ...prevVehiData.statusDetails,
                  ClaimRecord: deleteImgResponse.data,
                },
              }));
              setClaimObj(deleteImgResponse.data);
              await showAlert('success', '', 'Photo deleted successfully!');
            }
          } else {
            await showAlert('error', '', 'Failed to delete the photo. Please try again later.');
          }
        } catch (error) {
          console.log(error);
          if (error.response?.data === 'You do not have sufficient permissions to upload or delete images for a vehicle claimed by the dealer company.') {
            await showAlert('error', '', 'You do not have sufficient permissions to delete images for a vehicle claimed by the dealer company.');
          } else if (error.response?.data === 'Only authorized dealer personnel can upload or delete images for vehicles claimed by the dealer company.') {
            await showAlert('error', '', 'Only authorized dealer personnel can delete images for vehicles claimed by the dealer company.');
          } else {
            await showAlert('error', '', 'Failed to delete the photo. Please try again later.');
          }
        } finally {
          setIsLoading(false);
        }
      }
    } catch (error) {
      await showAlert('error', '', 'Failed to delete the photo. Please try again later.');
    }
  };

  const [activeSlide, setActiveSlide] = useState(0);

  const handleCarouselSelect = (selectedIndex, e) => {
    setActiveSlide(selectedIndex);
  };

  const numThumbnailsToDisplay = Math.min(5, validImages.length);

  const circularActiveSlide = (activeSlide + validImages.length) % validImages.length;

  // New code to ensure continuous circular looping of 5 thumbnails
  const displayedThumbnails = [];
  for (let i = 0; i < numThumbnailsToDisplay; i++) {
    displayedThumbnails.push(validImages[(circularActiveSlide + i) % validImages.length]);
  }

  const handleThumbnailClick = (index) => {
    setActiveSlide((circularActiveSlide + index) % validImages.length);
  };

  const handleThumbnailNavigation = (direction) => {
    if (direction === 'prev') {
      setActiveSlide((activeSlide - 1 + validImages.length) % validImages.length);
    } else if (direction === 'next') {
      setActiveSlide((activeSlide + 1) % validImages.length);
    }
  };

  return (
    <>
      {isLoading ? (
        <ButtonSpinner />
      ) : validImages.length > 0 ? (
        <div>
          <Carousel className="col-lg-8 col-md-8 col-12" activeIndex={activeSlide} onSelect={handleCarouselSelect} controls={false}>
            {validImages.map((image, i) => (
              <Carousel.Item key={i}>
                {token && showUploadDeleteBtn ? (
                  <div className="image-overlay-container" style={{ height: '100%' }}>
                    <img src={image.signedURL} alt={`${image.ImageID}`} />
                    <AiFillDelete className="carousel-delete-btn" onClick={() => deleteImage(image.ImageID)} />
                    <FiUpload className="carousel-upload-btn" onClick={() => setImgUploadModel(true)} />
                  </div>
                ) : (
                  <img src={image.signedURL} alt={`${image.ImageID}`} />
                )}
                <Carousel.Caption />
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="carousel-controls">
            <div className="horizontal-thumbnails">
              <FaAngleLeft className="thumbnail-navigation" onClick={() => handleThumbnailNavigation('prev')} />
              {displayedThumbnails.map((image, i) => (
                <img
                  key={i}
                  src={image.signedURL}
                  alt={`Thumbnail ${i}`}
                  className={`thumbnail ${i === circularActiveSlide ? 'thumbnail-highlighted' : ''}`}
                  onClick={() => handleThumbnailClick(i)}
                />
              ))}
              <FaAngleRight className="thumbnail-navigation" onClick={() => handleThumbnailNavigation('next')} />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            {token && showUploadDeleteBtn && (<FiUpload className="no-img-btn" onClick={() => setImgUploadModel(true)} />)}
            <p className="no-img-txt mt-2"> No images found.</p>
          </div>
        </div>
      )}
      <MultipleImageUpload
        imgUploadModel={imgUploadModel}
        setImgUploadModel={setImgUploadModel}
        claimID={claimID}
        fetchVehicleImages={fetchVehicleImages}
        images={images}
        setIsLoading={setIsLoading}
      />
    </>
  );
}
