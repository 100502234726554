import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { showAlert } from '../utils/AlertUtills';
import '../assets/CSS/DescriptionModel.css';
import { VehicleDataContext } from '../Context/VehicleDataContext';

export default function DescriptionModel({ show, description, setShowDescription, userID, ownerID, claimID, editability }) {
  const [newDescription, setNewDescription] = useState(description || '');
  const { token } = useContext(jwtToken);
  const { setVehiData } = useContext(VehicleDataContext);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    function isReadOnly() {
      if (userID === ownerID || editability) {
        setReadOnly(false);
      }
    }
    isReadOnly();
  }, [userID, ownerID, editability]);

  async function updateDescription() {
    try {
      setShowDescription(false);
      const regularExpression_badWords = /crap|ugly|brat|fool|fuck|fucking|f\*cking|f\*ck|bitch|b\*tch|shit|sh\*t|fool|dumb|couch potato|arse|arsehole|asshole|\*ssh\*l\*|\*\*\*\*|c\*ck|\*\*\*\*sucker|c\*cks\*ck\*r|\*\*\*\*|c\*nt|dickhead|d\*c\*h\*a\*|\*\*\*\*|f\*c\*|\*\*\*\*wit|f\*ckw\*t|fuk|f\*k|fuking|f\*k\*ng|mother\*\*\*\*er|m\*th\*rf\*ck\*r|\*\*\*\*\*\*|n\*gg\*r|pussy|p\*ssy|\*\*\*\*|sh\*t|wanker|w\*nk\*r|wankers|w\*nk\*rs|whore|wh\*r\*|slag| sl\*g|\*\*\*\*\*|b\*tch|f u c k|f\*c\*|b.i.t.c.h|b\*tch|d-i-c-k|d\*\*\*/gi;
      let descriptionStatus;
      if (regularExpression_badWords.test(newDescription)) {
        descriptionStatus = 'Pending';
      } else {
        descriptionStatus = 'Approved';
      }
      const response = await axios.post(`${process.env.REACT_APP_API_COMMON}update-list-record`, { userID, claimID, description: newDescription, descriptionStatus }, { headers: { Authorization: `Bearer ${token}` } });
      const updatedListRecord = response.data;
      if (response.status === 200) {
        setVehiData(prevData => ({
          ...prevData,
          statusDetails: {
            ...prevData.statusDetails,
            ListRecord: {
              ...prevData.statusDetails.ListRecord,
              ...updatedListRecord
            }
          }
        }));
        await showAlert('success', '', 'Description updated successfully!');
      }
    } catch (error) {
      await showAlert('error', '', 'Failed to update the description');
    }
  }

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDescription(false)}
        dialogClassName="wider-modal-dialog-box"
      >

        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div >
            <textarea className="form-control-textarea"
              placeholder='Please enter a brief description'
              value={newDescription}
              onChange={(e) => {
                e.preventDefault();
                setNewDescription(e.target.value);
              }}
              readOnly={readOnly}
              id="exampleFormControlTextarea1"
              rows="15"
              cols="400"
            >
            </textarea>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {(userID === ownerID || editability) && description !== newDescription && (
            <button className="btn btn-primary" onClick={updateDescription}>
              Submit updated content
            </button>
          )}
          <button className="btn btn-secondary" onClick={() => setShowDescription(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
