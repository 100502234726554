// Get redirect URLs from environment variable
const redirectUrls = process.env.REACT_APP_URL ? process.env.REACT_APP_URL.split(',') : [];

// Find matching redirect URL with error handling
const redirectUrl = Array.isArray(redirectUrls) && redirectUrls.length > 0
  ? redirectUrls.find((url) => url.includes(window.location.hostname)) || redirectUrls[0]
  : window.location.origin;

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: `${process.env.REACT_APP_UP_ID}`,
      userPoolClientId: `${process.env.REACT_APP_UPWC_ID}`,
      identityPoolId: `${process.env.REACT_APP_IDP_ID}`,
      region: `${process.env.REACT_APP_REGION}`,
      allowGuestAccess: true,
      loginWith: {
        oauth: {
          domain: `${process.env.REACT_APP_DOMAIN}`,
          scopes: ['email', 'openid'],
          redirectSignIn: [redirectUrl],
          redirectSignOut: [redirectUrl],
          responseType: 'code'
        },
      },
    },
  },
};

export default awsConfig;