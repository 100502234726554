import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';
import axios from 'axios';
import Button from '../claim/button';
import { jwtToken } from '../../Context/DataContext';
import { ButtonSpinner } from '../../components/Spinner';
import { showAlert, confirmAlert } from '../../utils/AlertUtills';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { UserContext } from '../../Context/UserContext';
import { VehicleDataContext } from '../../Context/VehicleDataContext';
import { MetadataContext } from '../../Context/MetadataContext';
import { GlobalContext } from '../../Context/GlobalContext';
import { getCurrentUTCTime, TimestampToUTC, UTCdatetimeToFormattedDateTime } from '../../utils/DateAndTimeUtils';
import { Spinner } from '../../components/Spinner';

import { Popover } from '@mui/material';
import infoLogo from '../../assets/img/about.svg';
import '../CSS/Steps.css';

export default function Stepone() {
  const { userID, user } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const { vehiData, claimObj, setVehiData, setClaimObj, plateNumber, VIN } = useContext(VehicleDataContext);
  const { dealerClaims, individualClaims, setDealerClaims, setIndividualClaims } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [partyType, setPartyType] = useState('');
  const [sellerErrorMessage, setSellerErrMessage] = useState('');
  const [stockStatusErrMsg, setStockStatusErrMsg] = useState('');
  const [locationErrorMessage, setLocationErrMessage] = useState();
  const [licenseNumber, setLicenseNumber] = useState('');
  const [individualErrorMessage, setIndividualErrorMessage] = useState();
  const [companyName, setCompanyName] = useState('');
  const [companyErrorMessage, setCompanyErrorMessage] = useState();
  const [traderNumber, setTraderNumber] = useState('');
  const [traderErrorMessage, setTraderErrorMessage] = useState();
  const [selectedLocation, setSelectedLocation] = useState('');
  const { locations } = useContext(MetadataContext);
  const [claimLoading, setClaimLoading] = useState(false);
  const [isTrader, setIsTrader] = useState(false);
  const [showLicenseInfo, setShowLicenseInfo] = useState(false);
  const items = ['Individual', 'Company'];
  //Outage scenario
  const [showClaimNZTA, setShowClaimNZTA] = useState(false);
  const [showClaimPPSR, setShowClaimPPSR] = useState(false);
  const [matchingClaimNZTAItem, setMatchingClaimNZTAItem] = useState({});
  const [matchingClaimPPSRItem, setMatchingClaimPPSRItem] = useState();
  const [loaded, setLoaded] = useState(false);
  const [isPendingClaim, setIsPendingClaim] = useState(false);
  const [limitType, setLimitType] = useState('');

  useEffect(() => {
    setIsPendingClaim(claimObj.Status === 'Pending' && claimObj.OwnerID === user.UserID);
    setIsTrader(claimObj.IsTrader === 'true' && claimObj.DealerID === user.DealerID);
    const getActiveOutage = async () => {
      if (token) {
        setLoaded(true);
        try {
          const outageResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}get-outagebystatus`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });

          const currentDateTime = getCurrentUTCTime();

          const isMatchingClaimNZTA = outageResponse.data.some(item => {
            if (item.API === 'CLAIM' && item.OutageType === 'NZTA' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              setMatchingClaimNZTAItem(item);
              return true;
            }
            return false;
          });

          const isMatchingClaimPPSR = outageResponse.data.some(item => {
            if (item.API === 'CLAIM' && item.OutageType === 'PPSR' &&
              currentDateTime > TimestampToUTC(item.OutageStartDateTime) && currentDateTime < TimestampToUTC(item.OutageEndDateTime)) {
              setMatchingClaimPPSRItem(item);
              return true;
            }
            return false;
          });

          if (isMatchingClaimNZTA) {
            setShowClaimNZTA(true);
          }
          if (isMatchingClaimPPSR) {
            setShowClaimPPSR(true);
          }

        } catch (error) {
          console.error("Error fetching", error);
        }
      }
      setLoaded(false);
    };
    getActiveOutage();
  }, [token]);

  const [stockStatus, setStockStatus] = useState('InStock');
  const radioStyle = {
    margin: '10px',
  };

  async function claim(e) {
    let endpoint = '';
    let sellerErrMsg = '';
    let stockStatusErrMsg = '';
    let locationErrMsg = '';
    let licenseOrCompanyOrTraderErrMsg = '';
    const data = { VIN, userID, selectedLocation };

    if (!partyType || !selectedLocation || !stockStatus) {
      sellerErrMsg = !partyType ? 'Please select the seller type' : '';
      locationErrMsg = !selectedLocation ? 'Please select the location' : '';
      stockStatusErrMsg = !stockStatus ? 'Please select the stock status' : '';
    }
    if (isTrader) {
      const traderNumberRegex = /^M/;
      licenseOrCompanyOrTraderErrMsg = !traderNumber ? 'Trader Number Required' : !traderNumberRegex.test(traderNumber) ? 'Invalid Trader Number' : '';
    } else {
      if (partyType === 'Individual') {
        // Validation for licenseNumber
        //This checks if two letters have at the beginning of the string  &  have six digits following the letters,
        // without any invalid characters like punctuations,spaces
        const licenseNumberReg = /^[a-zA-Z]{2}\d{6}$/i;
        licenseOrCompanyOrTraderErrMsg = !licenseNumber ? 'License Number Required' : !licenseNumberReg.test(licenseNumber) ? 'Invalid License Number' : '';
        endpoint = 'claimin';
        data.licenseNumber = licenseNumber;
      } else if (partyType === 'Company') {
        licenseOrCompanyOrTraderErrMsg = !companyName ? 'Company Name Required' : '';
        endpoint = 'claimcom';
        data.companyName = companyName;
      }
    }

    setSellerErrMessage(sellerErrMsg);
    setStockStatusErrMsg(stockStatusErrMsg);
    setLocationErrMessage(locationErrMsg);
    setIndividualErrorMessage(licenseOrCompanyOrTraderErrMsg);
    setCompanyErrorMessage(licenseOrCompanyOrTraderErrMsg);
    setTraderErrorMessage(licenseOrCompanyOrTraderErrMsg);

    if (sellerErrMsg || locationErrMsg || licenseOrCompanyOrTraderErrMsg || stockStatusErrMsg) {
      e.preventDefault();
      return;
    }

    if (isTrader) {
      setClaimLoading(true);
      try {
        const claimResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}tradervehiclaim`,
          { userID, VIN, traderNumber, claimType: partyType, selectedLocation, stockStatus }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (claimResponse.status === 200) {
          setVehiData(prevVehiData => ({
            ...prevVehiData,
            statusDetails: {
              ...prevVehiData.statusDetails,
              ClaimRecord: claimResponse.data,
              ClaimStatus: true,
            },
          }));
          setClaimObj(claimResponse.data);
          if (user.dealerID) {
            setDealerClaims([...dealerClaims, claimResponse.data]);
          } else {
            setIndividualClaims([...individualClaims, claimResponse.data]);
          }
          const hasFreePass = await checkFreePromotion(userID, claimResponse.data.ClaimID);
          const result = await confirmAlert('success', '', `You have successfully validated that you have the authority to list this vehicle.${hasFreePass ? '\nCongratulations! You got a free listing pass.' : ''}`, 'Next', 'Close');
          if (result.isConfirmed) {
            navigate('/claim/Not%20Listed?activeStep=2', { replace: true });
          } else {
            navigate(`/details`);
          }
        }
      } catch (error) {
        if (error.response && error.response.data.error === 'MVTR API unavailable.') {
          const result = await showAlert('warning', '', 'The claim function is not available now.');
          if (result.isConfirmed) {
            if (vehiData.ListStatus === 'NotListed') {
              navigate('/claim/Not%20Listed?activeStep=2', { replace: true });
            } else {
              navigate(`/details`);
            }
          }
        } else if (error.response && error.response.data.error === 'Invalid trader number.') {
          await showAlert('error', '', 'Invalid trader number. Please provide a valid trader number.');
        } else if (error.response && error.response.data.error === 'This vehicle has already been claimed.') {
          await showAlert('error', '', 'This vehicle has already been claimed. Please contact the seller.');
        } else if (error.response && error.response.status === 429) {
          await showAlert('warning', '', `${error.response.data}`);
        } else {
          await showAlert('error', '', 'Claim unsuccessful.');
        }
      } finally {
        setClaimLoading(false);
      }
    } else {
      setClaimLoading(true);
      try {
        const claimResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}${endpoint}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (claimResponse.status === 200) {
          setVehiData(prevVehiData => ({
            ...prevVehiData,
            statusDetails: {
              ...prevVehiData.statusDetails,
              ClaimRecord: claimResponse.data,
              ClaimStatus: true,
            },
          }));

          setClaimObj(claimResponse.data);
          setIndividualClaims([...individualClaims, claimResponse.data]);
          const hasFreePass = await checkFreePromotion(userID, claimResponse.data.ClaimID);
          const result = await confirmAlert('success', '', `You have successfully validated that you have the authority to list this vehicle.${hasFreePass ? '\nCongratulations! You got a free listing pass.' : ''}`, 'Next', 'Close');
          if (result.isConfirmed) {
            navigate('/claim/Not%20Listed?activeStep=2', { replace: true });
          } else {
            navigate(`/details`);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 503) {
          await showAlert('error', '', 'The claim function is not available now.');
        } else if (error.response && error.response.status === 404) {
          await showAlert('error', '', 'Sorry! You are not the Registered Vehicle Owner');
        } else if (error.response && error.response.status === 429) {
          await showAlert('warning', '', `${error.response.data}`);
        } else {
          await showAlert('error', '', 'Claim unsuccessful.');
        }
        setClaimLoading(false);
      } finally {
        setClaimLoading(false);
      }
    }
  }

  async function handelPendingClaim(e) {
    let sellerErrMsg = '';
    let stockStatusErrMsg = '';
    let locationErrMsg = '';
    let licenseOrCompanyOrTraderErrMsg = '';
    const data = { VIN, userID, selectedLocation };

    if (!partyType || !selectedLocation || !stockStatus)
      sellerErrMsg = !partyType ? 'Please select the seller type' : '';
    locationErrMsg = !selectedLocation ? 'Please select the location' : '';
    stockStatusErrMsg = !stockStatus ? 'Please select the stock status' : '';
    if (claimObj.IsTrader === 'true') {
      const traderNumberRegex = /^M/;
      licenseOrCompanyOrTraderErrMsg = !traderNumber ? 'Trader Number Required' : !traderNumberRegex.test(traderNumber) ? 'Invalid Trader Number' : '';
    } else {
      if (partyType === 'Individual') {
        const licenseNumberReg = /^[a-zA-Z]{2}\d{6}$/i;
        licenseOrCompanyOrTraderErrMsg = !licenseNumber ? 'License Number Required' : !licenseNumberReg.test(licenseNumber) ? 'Invalid License Number' : '';
        data.licenseNumber = licenseNumber;
      } else if (partyType === 'Company') {
        licenseOrCompanyOrTraderErrMsg = !companyName ? 'Company Name Required' : '';
        data.companyName = companyName;
      }
    }

    setSellerErrMessage(sellerErrMsg);
    setStockStatusErrMsg(stockStatusErrMsg);
    setLocationErrMessage(locationErrMsg);
    setIndividualErrorMessage(licenseOrCompanyOrTraderErrMsg);
    setCompanyErrorMessage(licenseOrCompanyOrTraderErrMsg);
    setTraderErrorMessage(licenseOrCompanyOrTraderErrMsg);

    if (sellerErrMsg || locationErrMsg || licenseOrCompanyOrTraderErrMsg || stockStatusErrMsg) {
      e.preventDefault();
      return;
    }
    setClaimLoading(true);
    try {
      let type = partyType === 'Individual' ? 'Individual' : 'Company';
      const claimResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}reclaim`,
        { userID, claimID: claimObj.ClaimID, licenseNumber, companyName, traderNumber, selectedLocation, type, stockStatus }, { headers: { Authorization: `Bearer ${token}` } }
      );
      if (claimResponse.status === 200) {
        setVehiData(prevVehiData => ({
          ...prevVehiData,
          statusDetails: {
            ...prevVehiData.statusDetails,
            ClaimRecord: claimResponse.data,
            ClaimStatus: true,
          },
        }));
        setClaimObj(claimResponse.data);
        if (user.dealerID) {
          setDealerClaims([...dealerClaims, claimResponse.data]);
        } else {
          setIndividualClaims([...individualClaims, claimResponse.data]);
        }
        const result = await confirmAlert('success', '', 'You have successfully validated that you have the authority to list this vehicle.', 'Next', 'Close');
        if (result.isConfirmed) {
          navigate(`/details`);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 503) {
        await showAlert('error', '', 'The claim function is not available now.');
      } else if (error.response && error.response.status === 429) {
        setLimitType(error.response.data);
        await showAlert('error', `${limitType}`);
      } else {
        await showAlert('error', '', 'Claim unsuccessful.');
      }
    } finally {
      setClaimLoading(false);
    }
  }

  async function handleSkip(e) {
    setIsTrader(false);
    navigate(`/claim/Not%20Listed?activeStep=3`, {
      state: { claimSkiped: true }
    });
  }
  const handleAutoTraderChange = async (e) => {
    setIsTrader(e.target.checked);

    if (e.target.checked && showClaimPPSR) {
      const start = UTCdatetimeToFormattedDateTime(TimestampToUTC(matchingClaimPPSRItem.OutageStartDateTime));
      const end = UTCdatetimeToFormattedDateTime(TimestampToUTC(matchingClaimPPSRItem.OutageEndDateTime));
      await showAlert('warning', 'Services Unavailable', `PPSR Reported Planned Outage - Claim\n\nFrom: ${start}\nTo: ${end}`);
      setIsTrader(false);
    }
  };

  const handlePartyTypeChange = async (event) => {
    const selectedPartyType = event.target.getAttribute('data-value');
    setPartyType(selectedPartyType);

    if (!isTrader && showClaimNZTA) {
      const start = UTCdatetimeToFormattedDateTime(TimestampToUTC(matchingClaimNZTAItem.OutageStartDateTime));
      const end = UTCdatetimeToFormattedDateTime(TimestampToUTC(matchingClaimNZTAItem.OutageEndDateTime));
      await showAlert('warning', 'Services Unavailable', `NZTA Reported Planned Outage - Claim\n\nFrom: ${start}\nTo: ${end}`);
      setPartyType('');
    }
  };

  async function checkFreePromotion(userID, claimID) {
    try {
      const freePromotionResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}promotion-pass`,
        { userID, claimID }, { headers: { Authorization: `Bearer ${token}` } });

      if (freePromotionResponse.status === 200) {
        return true;
      }
      return false;

    } catch (error) {
      return false;
    }
  }

  return (
    <>
      {loaded ? (<Spinner></Spinner>) : (
        <div className="container">
          <h2 style={{ color: 'black' }} className="text-center">
            List: Validate Ownership
          </h2>
          &nbsp;
          {(!isPendingClaim || (isPendingClaim && claimObj.DealerID)) && (
            <div className="form-group">
              <Form.Check
                type='checkbox'
                id={`trader-checkbox`}
                label={`Are you an Auto Trader?`}
                checked={isTrader}
                onChange={handleAutoTraderChange}
                disabled={isTrader && isPendingClaim}
              />
            </div>
          )}
          <br />
          {isTrader && (
            <div className="form-group">
              <label style={{ color: 'black' }}>Stock Status</label>
              <div>
                <Form.Check
                  type='radio'
                  id='inStock'
                  label='In Stock'
                  value='InStock'
                  checked={stockStatus === 'InStock'}
                  onChange={() => setStockStatus('InStock')}
                  style={radioStyle}
                />
                <Form.Check
                  type='radio'
                  id='comingSoon'
                  label='Coming Soon'
                  value='ComingSoon'
                  checked={stockStatus === 'ComingSoon'}
                  onChange={() => setStockStatus('ComingSoon')}
                  style={radioStyle}
                />
              </div>
              <p style={{ color: 'red' }}>{stockStatusErrMsg}</p>
            </div>
          )}
          <div className="form-group">
            <Dropdown>
              <Dropdown.Toggle variant="danger" id="dropdown-basic">
                {partyType || 'Select the seller type'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {items.map((item, i) => (
                  <Dropdown.Item onClick={handlePartyTypeChange} key={i + 'drop'} data-value={item}>
                    {item}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <p style={{ color: 'red' }}>{sellerErrorMessage}</p>
          </div>
          &nbsp;
          {plateNumber ? (
            <div className="form-group">
              <label style={{ color: 'black' }}>Plate Number</label>
              <input type="text" className="form-control mt-2" placeholder={plateNumber} readOnly />
            </div>
          ) : (
            <div className="form-group">
              <label style={{ color: 'black' }}>VIN</label>
              <input type="text" className="form-control mt-2" placeholder={VIN} readOnly />
            </div>
          )
          }
          &nbsp;
          {!isTrader && partyType === 'Individual' && (
            <div className="form-group">
              <label style={{ color: 'black' }}>License Number</label>
              <img style={{ marginLeft: '5px' }} src={infoLogo} alt="info logo" className="info_logo" onClick={(e) => setShowLicenseInfo(e.currentTarget)} />
              <Popover
                id={showLicenseInfo ? 'popover-6' : undefined}
                open={showLicenseInfo}
                style={{
                  width: '80%',
                }}
                anchorEl={showLicenseInfo}
                onClose={() => setShowLicenseInfo(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <p className='popover-text'>The driver license number collected here is only used for validation purpose against the relevant authority and not stored by Waka Online NZ for any further use.</p>
              </Popover>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter License Number"
                onChange={(e) => { setLicenseNumber(e.target.value); }}
              />
              <p style={{ color: 'red' }}>{individualErrorMessage}</p>
            </div>
          )}
          {!isTrader && partyType === 'Company' && (
            <div className="form-group">
              <label style={{ color: 'black' }}>Company Name</label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter Company Name"
                onChange={(e) => { setCompanyName(e.target.value); }}
              />
              <p style={{ color: 'red' }}>{companyErrorMessage}</p>
            </div>
          )}
          {isTrader && partyType && (
            <div className="form-group">
              <label style={{ color: 'black' }}>Trader Number</label>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Enter Trader Number"
                onChange={(e) => { setTraderNumber(e.target.value); }}
              />
              <p style={{ color: 'red' }}>{traderErrorMessage}</p>
            </div>
          )}
          &nbsp;
          <div className="d-flex justify-content-center">
            <FormControl sx={{ m: 1, minWidth: 120, zIndex: 10 }} color="error">
              <InputLabel id="demo-simple-select-helper-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedLocation}
                label="Location"
                onChange={(e) => { setSelectedLocation(e.target.value); }}
              >
                <MenuItem value="">
                  <em>Select a location</em>
                </MenuItem>
                {Object.entries(locations).flatMap(([Region, City], regionIndex) => [
                  <ListSubheader key={regionIndex + '-Region'} sx={{ color: 'Black', fontWeight: 'bold', paddingLeft: '16px' }}>
                    {Region}
                  </ListSubheader>,
                  ...City.map((city, cityIndex) => (
                    <MenuItem key={`${regionIndex}-${cityIndex}-city`} value={city} sx={{ paddingLeft: '32px' }}>
                      {city}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
            <p style={{ color: 'red' }}>{locationErrorMessage}</p>
          </div>
          &nbsp;
          <div align="center">
            <Button variant="danger" onClick={(isPendingClaim) ? handelPendingClaim : claim} disabled={claimLoading}>
              {claimLoading ? <ButtonSpinner /> : 'Validate'}
            </Button>
            {/* </div>
          <div align="center"> */}
            {!(claimObj.Status === 'Pending' || claimObj.Status === 'Active') && (
              <Button variant="danger" onClick={handleSkip} disabled={claimLoading}>
                Skip
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}