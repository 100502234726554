import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import {
  InputLabel, MenuItem, FormControl, Select, Button, Card, CardContent, CardActionArea, CardMedia,
  Typography, TextField, Autocomplete, Grid, Pagination
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import '../assets/CSS/MultiSearch.css';
import AOT_LOGO from '../assets/waka-logo/AOT_LOGO_GrayScale.png';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import MakeModelDropdown from './MakeModelDropdown';
import { Spinner } from './Spinner';
import { MetadataContext } from '../Context/MetadataContext';
import infoLogo from '../assets/img/about.svg';
import { Popover } from '@mui/material';
import SearchContext from '../Context/SearchContext';
import { UserContext } from '../Context/UserContext';
import { jwtToken } from '../Context/DataContext';
import { showAlert } from '../utils/AlertUtills';
import { addUserPreferences } from '../services/PreferenceService';

const MultiSearch = () => {
  const { userID } = useContext(UserContext);
  const { token } = useContext(jwtToken);
  const location = useLocation();
  const navigate = useNavigate();
  const { locations, bodyStyles, activeDealers } = useContext(MetadataContext);
  const { selectedMake, setSelectedMake, selectedModel, setSelectedModel, selectedYearRangeStart, setSelectedYearRangeStart,
    selectedYearRangeEnd, setSelectedYearRangeEnd, selectedFuelType, setSelectedFuelType, selectedBodyStyle, setSelectedBodyStyle, selectedDealerCompany,
    setSelectedDealerCompany, selectedLocation, setSelectedLocation, selectedStatus, setSelectedStatus,
    selectedOdometerRange, setSelectedOdometerRange, selectedPriceRange, setSelectedPriceRange, searchResults,
    setSearchResults, vehicles, setVehicles, searchMessage, setSearchMessage, totalPages, setTotalPages,
    loading, setLoading } = useContext(SearchContext);
  const [yearRangeStartOptions, setYearRangeStartOptions] = useState([]);
  const [yearRangeEndOptions, setYearRangeEndOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorStartYear, setErrorStartYear] = useState('');
  const [errorEndYear, setErrorEndYear] = useState('');
  const [NotListedPop, setNotListedPop] = useState('');
  const [dealerQueryParam, setDealerQueryParam] = useState('');
  // const [savePreference, setSavePreference] = useState(false);
  const advancedSearchButtonRef = useRef(null);
  const searchResultsRef = useRef(null);
  const perPage = 20;
  const [showSavePrefModal, setShowSavePrefModal] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const traderNo = queryParameters.get("trader-no");

    if (traderNo) {
      const dealerCompany = activeDealers.find(item => item.TraderNumber === traderNo);
      if (dealerCompany) {
        setSelectedDealerCompany(dealerCompany.DealerID);
        setDealerQueryParam(dealerCompany.DealerID);
      } else {
        setLoading(false);
        setSelectedDealerCompany(null);
        setSearchResults([]);
        setSearchMessage('No Results Found, Invalid trader');
      }
    }
  }, [location, activeDealers]);

  useEffect(() => {
    if (dealerQueryParam) {
      handleSubmit(1);
    }
  }, [dealerQueryParam]);

  const cardbottom = {
    paddingTop: '0px',
    paddingBottom: '8px',
  };
  const vehicle_card_font = {
    fontFamily: 'PT Sans, sans-serif',
  };

  const notListedInfoClick = (event) => {
    setNotListedPop(event.currentTarget);
  };

  const notListedClose = () => {
    setNotListedPop(null);
  };

  const openNotListedPop = Boolean(NotListedPop);
  const idNotListedPop = openNotListedPop ? 'popover-6' : undefined;

  const handleSavePreferenceChange = () => {
    setShowSavePrefModal(true);
  };
  useEffect(() => {
    if (location?.state?.fetchdata) {
      handleSubmit(1);
    }
  }, []);

  useEffect(() => {
    const allYears = [];
    const currentYear = new Date().getFullYear();
    for (let year = 1960; year <= currentYear; year++) {
      allYears.push(year);
    }
    setYearRangeStartOptions(allYears);
    setYearRangeEndOptions(allYears);
  }, [vehicles]);

  const handleBodyStyleChange = (selectedBodyStyle) => {
    setSelectedBodyStyle(selectedBodyStyle);
  };

  const handleLocationChange = (selectedLocation) => {
    if (selectedLocation && selectedLocation.indented) {
      setSelectedLocation(selectedLocation.value);
    } else {
      setSelectedLocation('');
    }
  };

  const handleViewMoreClick = (VIN) => {
    navigate('/details', {
      state: { plateNumberOrVIN: VIN }
    });
  };

  const isAttributeSelected = (
    selectedMake || selectedModel || selectedYearRangeStart || selectedYearRangeEnd || selectedFuelType ||
    selectedBodyStyle || selectedLocation || selectedOdometerRange || selectedPriceRange || selectedStatus || selectedDealerCompany
  );

  const handleSubmit = async (page) => {
    if (selectedDealerCompany) {
      setQueryParams();
    } else {
      removeQueryParams();
    }
    setErrorStartYear('');
    setErrorEndYear('');
    setCurrentPage(page);
    setLoading(true);
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const searchParams = {
      selectedMake, selectedModel, selectedYearRangeStart, selectedYearRangeEnd, selectedFuelType,
      selectedBodyStyle, selectedLocation, selectedOdometerRange, selectedPriceRange, selectedStatus, selectedDealer: selectedDealerCompany,
      startIndex, endIndex, maxResults: perPage
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_SEARCH_API}find`, searchParams);
      const { vehicles, totalPages } = response.data;
      setVehicles(vehicles);
      setTotalPages(totalPages);
      if (vehicles.length === 0) {
        setSearchResults([]);
        setSearchMessage('No Results Found, Maybe not in the Active State');
      } else {
        const updatedSearchResults = await Promise.all(
          vehicles.map(async (vehicle) => {
            return { ...vehicle };
          }),
        );
        setSearchResults(updatedSearchResults);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
    if (searchResultsRef.current) {
      searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReset = () => {
    setSelectedMake(null);
    setSelectedModel(null);
    setSelectedYearRangeStart('');
    setSelectedYearRangeEnd('');
    setSelectedFuelType('');
    setSelectedBodyStyle(null);
    setSelectedLocation('');
    setSelectedOdometerRange(null);
    setSelectedPriceRange(null);
    setSelectedStatus('');
    setSearchResults([]);
    setErrorStartYear('');
    setErrorEndYear('');
    setSelectedDealerCompany(null);
    removeQueryParams();
  };

  const removeQueryParams = () => {
    const queryParameters = new URLSearchParams(location.search);
    queryParameters.delete("trader-no");
    navigate({
      search: queryParameters.toString(),
      replace: true
    });
  };

  const setQueryParams = () => {
    const dealerCompany = activeDealers.find(item => item.DealerID === selectedDealerCompany);
    if (dealerCompany) {
      const queryParameters = new URLSearchParams(location.search);
      queryParameters.set("trader-no", dealerCompany.TraderNumber);
      navigate({
        search: queryParameters.toString(),
        replace: true
      });
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) => option.toLowerCase().startsWith(inputValue.toLowerCase()));
  };

  const savePref = async () => {
    setShowSavePrefModal(false);
    try {
      const prefResponse = await addUserPreferences(userID, token, {
        Make: selectedMake,
        Model: selectedModel,
        StartYear: selectedYearRangeStart,
        EndYear: selectedYearRangeEnd,
        FuelType: selectedFuelType
      });
      if (prefResponse.status === 200) {
        await showAlert('success', '', 'Preferences saved successfully!');
      }
    } catch (error) {
      console.log('Error saving preferences.', error.message);
      if (error.response.status === 401) {
        error.message = 'Unauthorized Access';
        console.log('Unauthorized access. Redirecting to login...');
      }
      await showAlert('error', '', error.message);
    }
  };

  const shouldShowSavePreference = token && selectedMake && selectedModel;

  return (
    <div className="App p-spacing" style={{ minHeight: '100%', position: 'relative' }}>
      <div className="container-lg m-spacing">
        <div className="box_border  container" style={{ border: '1px solid white', paddingTop: '20px' }}>
          <h2 style={{ color: '#444242', fontFamily: 'bookman old style', letterSpacing: '4px' }} className="text-center">
            Cars for Sale NZ
          </h2>
          <h6 style={{ letterSpacing: '3px', color: '#525252', textAlign: 'Center' }}>Find Cars Online</h6>
          <br />
          <div className="d-flex flex-wrap justify-content-center">
            <MakeModelDropdown
              selectedMake={selectedMake}
              setSelectedMake={setSelectedMake}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
            />

            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <InputLabel id="demo-simple-select-helper-label-start">Start Year</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-start"
                id="demo-simple-select-helper-start"
                value={selectedYearRangeStart}
                label="Start Year"
                onChange={(e) => {
                  if (selectedYearRangeEnd !== '' && e.target.value > selectedYearRangeEnd) {
                    setErrorStartYear('Start year must be less than or equal to end year');
                  } else {
                    setErrorStartYear('');
                    setSelectedYearRangeStart(e.target.value);
                  }
                }}
              >
                <MenuItem value="" sx={{ typography: 'body2' }}>
                  <em>None</em>
                </MenuItem>
                {yearRangeStartOptions.map((option, index) => (
                  <MenuItem key={index} value={option} sx={{ typography: 'body2' }}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {errorStartYear && <span style={{ color: 'red' }}>{errorStartYear}</span>}
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <InputLabel id="demo-simple-select-helper-label-end">End Year</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-end"
                id="demo-simple-select-helper-end"
                value={selectedYearRangeEnd}
                label="End Year"
                onChange={(e) => {
                  if (selectedYearRangeStart !== '' && e.target.value < selectedYearRangeStart) {
                    setErrorEndYear('End year must be greater than or equal to start year');
                  } else {
                    setErrorEndYear('');
                    setSelectedYearRangeEnd(e.target.value);
                  }
                }}
              >
                <MenuItem value="" sx={{ typography: 'body2' }}>
                  <em>None</em>
                </MenuItem>
                {yearRangeEndOptions.map((option, index) => (
                  <MenuItem key={index} value={option} sx={{ typography: 'body2' }}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {errorEndYear && <span style={{ color: 'red' }}>{errorEndYear}</span>}
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <InputLabel id="demo-simple-select-helper-label-fueltype">Fuel Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-fueltype"
                id="demo-simple-select-helper-fueltype"
                value={selectedFuelType}
                label="Fuel Type"
                onChange={(e) => { setSelectedFuelType(e.target.value); }}
              >
                <MenuItem value=""> <em>None</em> </MenuItem>
                <MenuItem value="Petrol">Petrol</MenuItem>
                <MenuItem value="Diesel">Diesel</MenuItem>
                <MenuItem value="Electric">Electric</MenuItem>
                <MenuItem value="Hybrid">Hybrid</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="d-flex flex-wrap justify-content-center">
            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <Autocomplete
                id="demo-simple-select-helper-end"
                options={bodyStyles}
                renderInput={(params) => <TextField {...params} label="Body style" color="error" />}
                value={selectedBodyStyle}
                filterOptions={filterOptions}
                onChange={(event, value) => {
                  handleBodyStyleChange(value);
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170, zIndex: 10, mx: 3 }} color="error">
              <Autocomplete
                id="location-autocomplete"
                value={selectedLocation}
                onChange={(event, value) => {
                  handleLocationChange(value);
                }}
                options={[
                  ...Object.entries(locations).flatMap(([Region, City]) => [
                    { value: Region, label: Region, disabled: true },
                    ...City.map((city) => ({
                      value: city,
                      label: city,
                      indented: true,
                    })),
                  ]),
                ]}
                groupBy={(option) => option.Region}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.indented ? (
                      <span style={{ paddingLeft: '1rem' }}>{option.label}</span>
                    ) : option.disabled ? (
                      <strong>{option.label}</strong>
                    ) : (
                      <span onClick={() => handleLocationChange(option)}>{option.label}</span>
                    )}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Location" />}
                isOptionEqualToValue={(option, value) => option.value === value || option.label === value}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  if (locations.hasOwnProperty(inputValue)) {
                    return [
                      { value: inputValue, label: inputValue, isDisabled: true },
                      ...locations[inputValue].map((city) => ({
                        value: city,
                        label: city,
                        indented: true,
                      })),
                    ];
                  } else {
                    return options.filter((option) => option.label.toLowerCase().startsWith(inputValue));
                  }
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <InputLabel id="demo-simple-select-helper-label-status">Status</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-status"
                id="demo-simple-select-helper-status"
                value={selectedStatus}
                label="Status"
                onChange={(e) => { setSelectedStatus(e.target.value); }}
              >
                <MenuItem value=""> <em>None</em> </MenuItem>
                <MenuItem value="claimed">Claimed</MenuItem>
                <MenuItem value="listed">Listed</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 170, mx: 3 }} color="error">
              <InputLabel id="demo-simple-select-helper-label-dealer">Dealer Company</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-dealer"
                id="demo-simple-select-helper-dealer"
                value={selectedDealerCompany}
                label="Dealer Company"
                onChange={(e) => {
                  setSelectedDealerCompany(e.target.value);
                }}
              >
                <MenuItem value="" sx={{ typography: 'body2' }}>
                  <em>None</em>
                </MenuItem>
                {activeDealers.map((option, index) => (
                  <MenuItem key={index} value={option.DealerID} sx={{ typography: 'body2' }}>
                    {option.CompanyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '70%', marginBottom: '20px', alignSelf: 'center' }}>
              <Typography variant="subtitle1" gutterBottom>
                Current Odometer Reading (km)
              </Typography>
              <Slider
                range
                min={0}
                max={200000}
                marks={{
                  0: '0',
                  200000: '200000+',
                }}
                value={selectedOdometerRange}
                onChange={(newRange) => {
                  setSelectedOdometerRange(newRange);
                }}
                trackStyle={{ backgroundColor: 'red' }}
                handleStyle={{ borderColor: 'red', backgroundColor: 'white' }}
                activeHandleStyle={{ borderColor: 'red', boxShadow: '0 0 0 red' }}
                style={{ marginBottom: '25px', width: '100%' }}
              />
              {selectedOdometerRange && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <span>{selectedOdometerRange[0]}</span>
                  <span>{selectedOdometerRange[1] >= 200000 ? '200000+' : selectedOdometerRange[1]}</span>
                </div>
              )}

              <Typography variant="subtitle1" gutterBottom>
                Vehicle Price ($)
              </Typography>
              <Slider
                style={{ color: 'red', marginBottom: '25px', width: '100%' }}
                range
                min={0}
                max={100000}
                marks={{
                  0: '0',
                  100000: '100000+',
                }}
                value={selectedPriceRange}
                onChange={(newRange) => {
                  setSelectedPriceRange(newRange);
                }}
                trackStyle={{ backgroundColor: 'red' }}
                handleStyle={{ borderColor: 'red', backgroundColor: 'white' }}
                activeHandleStyle={{ borderColor: 'red', backgroundColor: 'red', boxShadow: '0 0 0 red' }}
              />
              {selectedPriceRange && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                  <span>{selectedPriceRange[0]}</span>
                  <span>{selectedPriceRange[1] >= 100000 ? '100000+' : selectedPriceRange[1]}</span>
                </div>
              )}
            </div>

            <div className="row" style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button
                  ref={advancedSearchButtonRef}
                  sx={{ minWidth: 120, minHeight: 50 }}
                  variant="contained"
                  color="error"
                  onClick={() => handleSubmit(1)}
                >
                  Search
                </Button>
                <Button
                  sx={{ minWidth: 120, minHeight: 50 }}
                  variant="contained"
                  color="error"
                  onClick={handleReset}
                  disabled={!isAttributeSelected}
                >
                  Reset
                </Button>
              </div>
              {shouldShowSavePreference && (
                <div className="prefButton" style={{ display: 'flex', justifyContent: 'end', marginTop: '-50px' }}>
                  <Button
                    sx={{ minWidth: 120, minHeight: 50 }}
                    variant="contained"
                    color="error"
                    onClick={handleSavePreferenceChange}
                    disabled={!isAttributeSelected}
                  >
                    Set as Preference
                  </Button>
                </div>
              )}
            </div>
            <Modal show={showSavePrefModal} backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              onHide={() => setShowSavePrefModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Save Your Search Preference</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>Do you want to save this Search Preference?</div><br />
                {selectedMake && (
                  <tr>
                    <th className="text-th">Make:</th>
                    <td>{selectedMake}</td>
                  </tr>
                )}
                {selectedModel && (
                  <tr>
                    <th className="text-th">Model:</th>
                    <td>{selectedModel}</td>
                  </tr>
                )}
                {selectedYearRangeStart && (
                  <tr>
                    <th className="text-th">Start Year:</th>
                    <td>{selectedYearRangeStart}</td>
                  </tr>
                )}
                {selectedYearRangeEnd && (
                  <tr>
                    <th className="text-th">End Year:</th>
                    <td>{selectedYearRangeEnd}</td>
                  </tr>
                )}
                {selectedFuelType && (
                  <tr>
                    <th className="text-th">Fuel Type:</th>
                    <td>{selectedFuelType}</td>
                  </tr>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary" variant="secondary" onClick={() => setShowSavePrefModal(false)}>
                  Close
                </button>
                <button className="btn btn-primary" variant="primary" onClick={savePref}>
                  Save Preferences
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>

      {loading ? (<Spinner style={{ top: '10000px' }}></Spinner >) : (
        <div ref={searchResultsRef} className="container-lg mb-4 ">
          {searchResults.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, page) => handleSubmit(page)}
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: '#c9302c',
                  },
                }}
              />
            </div>
          )}
          <div>
            {searchResults.length > 0 ? (
              searchResults.map((vehicle, index) => (
                <div key={index}>
                  <Card className="card1">
                    <CardActionArea>
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          {!loading && (
                            <div style={{ position: 'relative', maxHeight: '230px', paddingBottom: '10px' }}>
                              <CardMedia
                                component="img"
                                id="mul_img"
                                style={{
                                  maxHeight: '230px',
                                  paddingTop: '10px',
                                  paddingBottom: '10px',
                                  paddingLeft: '10px',
                                  objectFit: 'cover',
                                }}
                                image={vehicle.signedURL || AOT_LOGO}
                                alt="Vehicle Image"
                              />
                              <img
                                src={vehicle.LogoURL}
                                alt="Logo"
                                style={{
                                  position: 'absolute',
                                  bottom: 1,
                                  left: 1,
                                  width: '70px',
                                  height: '70px',
                                  padding: '10px',
                                  objectFit: 'contain',
                                  borderRadius: '50px',
                                  background: 'transparent',
                                  opacity: (vehicle.LogoURL && vehicle.LogoURL[0] !== null) ? 1 : 0.0,
                                }}
                              />
                            </div>
                          )}{' '}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <CardContent>
                            <Typography style={vehicle_card_font} variant="h5" component="div">
                              {vehicle.make}
                            </Typography>
                            <Typography
                              style={vehicle_card_font}
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                            >
                              Model: {vehicle.model}
                            </Typography>
                            <Typography style={vehicle_card_font} variant="body2" color="text.secondary">
                              Year of Manufacture: {vehicle.yearOfManufacture}
                            </Typography>
                            <Typography style={vehicle_card_font} variant="body2" color="text.secondary">
                              BodyStyle: {vehicle.bodyStyle}
                            </Typography>
                            <Typography style={vehicle_card_font} variant="body2" color="text.secondary">
                              Current Odometer Reading: {vehicle.currentOdometerReading} km
                            </Typography>
                            <Typography style={vehicle_card_font} variant="body2" color="text.secondary">
                              Location: {vehicle.location}
                            </Typography>
                          </CardContent>

                          <CardContent style={cardbottom}>
                            {vehicle.listed.includes('1') ? (
                              <div>
                                Vehicle Price : $ {vehicle.vehiclePrice}
                              </div>
                            ) : (
                              <div>
                                Vehicle Price :  Not Listed
                                <img style={{ marginLeft: '5px' }} src={infoLogo} alt="info logo" className="info_logo" onClick={notListedInfoClick} />
                                <Popover
                                  id={idNotListedPop}
                                  open={openNotListedPop}
                                  style={{
                                    width: '90%',
                                  }}
                                  anchorEl={NotListedPop}
                                  onClose={notListedClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                >
                                  <p className='popover-text'>If interested you can log in and request the owner directly to list</p>
                                </Popover>
                              </div>
                            )}
                            <div>
                              <Button
                                style={{ float: 'right', bottom: '0', margin: '0 auto ' }}
                                size="medium"
                                variant="contained"
                                color="error"
                                onClick={() => handleViewMoreClick(vehicle.VIN[0])}
                              >
                                View More
                              </Button>
                            </div>
                          </CardContent>
                          {vehicle.listed.includes('1') ? (
                            <span style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'green', color: 'white', padding: '2px 6px', borderRadius: '4px', marginRight: '15px' }}>
                              Listed
                            </span>
                          ) : (
                            <span style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'orange', color: 'white', padding: '2px 6px', borderRadius: '4px', marginRight: '15px' }}>
                              Not Listed
                            </span>
                          )
                          }
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Card>
                  <br />
                </div>
              ))
            ) : (
              <Typography variant="subtitle1" color="textSecondary">
                {searchMessage}
              </Typography>
            )}
          </div>
          {searchResults.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, page) => handleSubmit(page)}
                sx={{
                  '& .MuiPaginationItem-root': {
                    color: '#c9302c',
                  },
                }}
              />
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
};

export default MultiSearch;
