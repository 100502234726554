import React, { useState, useEffect, useContext, useCallback } from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/RequestedVehiList.css';
import axios from 'axios';
import { jwtToken } from '../Context/DataContext';
import { Spinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import Accordion from 'react-bootstrap/Accordion';
import { showAlert } from '../utils/AlertUtills';

export default function Offers() {
  const { userID } = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { token } = useContext(jwtToken);

  const fetchRequestsData = useCallback(async () => {
    if (token && userID) {
      try {
        setLoaded(true);
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}listRequestsCount`,
          { userID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        setRequests(response.data);
      } catch (error) {
        showAlert('error', '', 'Failed to retrieve list requests!');
      } finally {
        setLoaded(false);
      }
    }
  }, [token, userID]);

  useEffect(() => {
    fetchRequestsData();
  }, [fetchRequestsData]);

  return (
    <>
      {loaded ? (<Spinner></Spinner>) : (
        <div className="App p-spacing">
          <div className="container m-spacing">
            {(requests?.DealerListRequests?.length === 0 && requests?.IndividualListRequests?.length === 0) ? (
              <div className="text-center mt-5 pt-5 pb-5 mb-5">No Requests</div>
            ) : (
              <>
                {requests?.IndividualListRequests?.length > 0 && (
                  <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Individual List Requests</Accordion.Header>
                      <Accordion.Body>
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th>Plate Number/VIN</th>
                              <th>Number of Requests</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requests.IndividualListRequests.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                </td>
                                <td>
                                  <p className="text-muted mb-0">{item.NoOfRequests}</p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
                }
                {requests?.DealerListRequests?.length > 0 && (
                  <Accordion defaultActiveKey={['1']} alwaysOpen>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Dealer List requests</Accordion.Header>
                      <Accordion.Body>
                        <table className="table align-middle mb-0 mt-2 bg-white">
                          <thead className="bg-light">
                            <tr>
                              <th>Plate Number/VIN</th>
                              <th>Number of Requests</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requests.DealerListRequests.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                </td>
                                <td>
                                  <p className="text-muted mb-0">{item.NoOfRequests}</p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
                }
              </>
            )}
          </div>
        </div >
      )
      }
    </>
  );

}